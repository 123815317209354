import { OrderCriteria } from '../../../lib/js-apiclient';
import { ChannelProductVariantApiClient } from './ChannelProductVariantApiClient';

export class ChannelProductVariantModel {
    static SYNC_STATUS = {
        success: 'success',
        pending: 'pending',
        error: 'error',
    }

    _token = null;
    _channelProductId = null;

    constructor(token, channelProductId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._channelProductId = (channelProductId !== null && channelProductId !== undefined) ? channelProductId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getChannelProductVariantBindingById(id) {
        const channelProductVariantClient = new ChannelProductVariantApiClient(this._token, this._channelProductId, id);
        return channelProductVariantClient.findOne();
    }

    async getChannelProductVariants(page, term, sorting, filters) {
        const channelProductVariantClient = new ChannelProductVariantApiClient(this._token, this._channelProductId);
        channelProductVariantClient.setPagingStep(10);

        if (term != null && term.length > 2) channelProductVariantClient.filterByString(term);

        if (sorting) {
            let orderCriteria = [];
            if (sorting.id !== null) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10))
            if (sorting.channelId !== null) orderCriteria.push((new OrderCriteria('channel_id', sorting.channelId)).setWeight(20))
            if (sorting.productId !== null) orderCriteria.push((new OrderCriteria('product_id', sorting.productId)).setWeight(30))
            if (sorting.productVariantId !== null) orderCriteria.push((new OrderCriteria('product_variant_id', sorting.productVariantId)).setWeight(50))
            if (sorting.externalId !== null) orderCriteria.push((new OrderCriteria('external_id', sorting.externalId)).setWeight(60))
            if (sorting.queueUuid !== null) orderCriteria.push((new OrderCriteria('queue_uuid', sorting.queueUuid)).setWeight(70))
            if (sorting.lastAction !== null) orderCriteria.push((new OrderCriteria('last_action', sorting.lastAction)).setWeight(80))
            if (sorting.syncStatus !== null) orderCriteria.push((new OrderCriteria('sync_status', sorting.syncStatus)).setWeight(90))
            if (sorting.error !== null) orderCriteria.push((new OrderCriteria('error', sorting.error)).setWeight(100))
            if (sorting.startSyncAt !== null) orderCriteria.push((new OrderCriteria('start_sync_at', sorting.startSyncAt)).setWeight(110))
            if (sorting.syncedAt !== null) orderCriteria.push((new OrderCriteria('synced_at', sorting.syncedAt)).setWeight(120))
            if (orderCriteria.length > 0) {
                channelProductVariantClient.orderBy(orderCriteria);
            }
        }

        if (page !== null) {
            // console.log("fetchPage");
            return await channelProductVariantClient.fetchPage(page);
        } else {
            // console.log("findAll");
            return await channelProductVariantClient.findAll();
        }
    }
}