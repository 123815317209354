import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'

import { ProductModel } from '../models/ProductModel';


const productVariantMediaAdapter = createEntityAdapter()

export const productVariantMediaApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'product/variant/media/fetchAll', 
        async ({variantId, sorting}, thunkAPI) => {
            try {
                const token = productVariantMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                return await productModel.getProductVariantMedias(variantId, sorting);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'product/variant/media/update', 
        async ({variantId, media}, thunkAPI) => {
            try {
                const token = productVariantMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                return await productModel.updateProductVariantMedia(variantId, media);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'product/variant/media/delete', 
        async ({variantId, media}, thunkAPI) => {
            try {
                const token = productVariantMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                await productModel.deleteProductVariantMedia(variantId, media.id);
                return media;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'product/variant/media/create', 
        async ({variantId, media, uploadUuid}, thunkAPI) => {
            try {
                const token = productVariantMediaApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                const newMedia = await productModel.createProductVariantMedia(variantId, media);
                return {...newMedia, uploadUuid: uploadUuid}
            } catch (apiError) {
                return thunkAPI.rejectWithValue({uuid: uploadUuid, error: apiError.toJson()});
            }
        }
    ),
}

const productVariantMediaSlice = createSlice({
    name: "product/variant/media",
    initialState: productVariantMediaAdapter.getInitialState({
        status: 'idle',
        error: null,
        uploadErrors: {},
        lastSelectedVariant: null,
    }),
    reducers: {
        sortMedia(state, action) {
            productVariantMediaAdapter.setAll(state, action.payload);
        },
        cleanMedia(state, action) {
            productVariantMediaAdapter.removeAll(state);
            state.uploadErrors = {}
        },
        setLastSelectedVariant(state, action) {
            state.lastSelectedVariant = action.payload
        }
    },
    extraReducers: {
        [productVariantMediaApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'product/variant/media/fetchAll/succeeded'
            state.error = null;
            productVariantMediaAdapter.setAll(state, action.payload)
        },
        [productVariantMediaApi.fetchAll.rejected]: (state, action) => {
            state.status = 'product/variant/media/fetchAll/rejected'
            state.error = action.payload
        },
        [productVariantMediaApi.create.fulfilled]: (state, action) => {
            state.status = 'product/variant/media/create/succeeded'
            state.error = null;
            productVariantMediaAdapter.addOne(state, action.payload);
        },
        [productVariantMediaApi.create.rejected]: (state, action) => {
            state.status = 'product/variant/media/create/rejected'
            state.uploadErrors = { ...state.uploadErrors, [action.payload.uuid]: action.payload.error}
        },
        [productVariantMediaApi.update.fulfilled]: (state, action) => {
            state.status = 'product/variant/media/update/succeeded'
            state.error = null;
            productVariantMediaAdapter.updateOne(state, action.payload);
        },
        [productVariantMediaApi.update.rejected]: (state, action) => {
            state.status = 'product/variant/media/update/rejected'
            state.error = action.payload
        },
        [productVariantMediaApi.delete.fulfilled]: (state, action) => {
            state.status = 'product/variant/media/delete/succeeded'
            state.error = null;
            productVariantMediaAdapter.removeOne(state, action.payload.id);
        },
        [productVariantMediaApi.delete.rejected]: (state, action) => {
            state.status = 'product/variant/media/delete/rejected'
            state.error = action.payload
        },
    }
});

export const selectSortedProductVariantMedias = createSelector(
    (state) => selectAllProductVariantMedias(state),
    (medias) => {
        return medias.sort((a, b)=>{ 
            return (a.weight - b.weight) 
        });
    }
);

export const {
    selectById: selectProductVariantMediaById,
    selectAll: selectAllProductVariantMedias,
} = productVariantMediaAdapter.getSelectors((state) => state.productVariantMediaReducer)

export const { sortMedia, cleanMedia, setLastSelectedVariant } = productVariantMediaSlice.actions;

export default productVariantMediaSlice.reducer;
