import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { AvgAnalyticsModel } from '../models/AvgAnalyticsModel';

const avgAnalyticsAdapter = createEntityAdapter()

export const avgAnalyticsApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'avgAnalytics/fetchAll',
        async ({ page, term, sorting, filters, avgType }, thunkAPI) => {
            try {
                const token = avgAnalyticsApi._getToken(thunkAPI);
                const avgAnalyticsModel = new AvgAnalyticsModel(token, AvgAnalyticsModel.TYPE_FASHION);

                if (page !== null) {
                    /** @type {Pager} */
                    const pager = await avgAnalyticsModel.getAnalytics(page, term, sorting, filters, avgType);

                    return {
                        hasPrevPage: pager.hasPrevPage,
                        hasNextPage: pager.hasNextPage,
                        entities: pager.getEntities()
                    }
                } else {
                    return {
                        hasPrevPage: null,
                        hasNextPage: null,
                        entities: await avgAnalyticsModel.getAnalytics(page, term, sorting, filters, avgType),
                    }
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const avgAnalyticsSlice = createSlice({
    name: "avgAnalytics",
    initialState: avgAnalyticsAdapter.getInitialState({
        status: 'idle',
        error: null,
        avgType: AvgAnalyticsModel.AVG_TYPE.all.value,
        term: null,
        sorting: { ///TODO is useful?
            id: null,
            seasonCode: null,
            categoryCode: null,
            brandCode: null,
        },
        filters: null,
        showMore: true,
    }),
    reducers: {
        setAvgType(state, action) {
            state.avgType = action.payload
        },
        clearAvgAnalytics(state) {
            state.clearListOnResponse = true
        },
    },
    extraReducers: {
        [avgAnalyticsApi.fetchAll.pending]: (state, action) => {
            state.status = 'avgAnalytics/fetchAll/pending';
        },
        [avgAnalyticsApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'avgAnalytics/fetchAll/succeeded';
            if(state.clearListOnResponse) {
                avgAnalyticsAdapter.setAll(state, action.payload.entities)
            } else {
                avgAnalyticsAdapter.addMany(state, action.payload.entities)
            }
            state.clearListOnResponse = false
            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [avgAnalyticsApi.fetchAll.rejected]: (state, action) => {
            state.status = 'avgAnalytics/fetchAll/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectById: selectAnalyticsById,
    selectAll: selectAllAnalytics,
} = avgAnalyticsAdapter.getSelectors((state) => state.avgAnalyticsReducer)

export const { setAvgType, clearAvgAnalytics } = avgAnalyticsSlice.actions;

export default avgAnalyticsSlice.reducer;