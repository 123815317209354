import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
    createSelector, 
} from '@reduxjs/toolkit'

import moment from 'moment';

import { NotificationModel } from '../models/NotificationModel';

export const notificationAdapter = createEntityAdapter({
    // sortComparer: (a, b) => {
    //   return a.label.localeCompare(b.label)
    // }
});

export const notificationApi = {
  fetchLast: createAsyncThunk(
    'notification/fetchLast', 
    async (_, thunkAPI) => {
      const token = thunkAPI.getState().identityReducer.token.signature;
      const model = new NotificationModel(token);

      try {
          return await model.getLastNotifications();
      } catch (apiError) {
          return thunkAPI.rejectWithValue(apiError.toJson());
      }
    }
  ),
  update: createAsyncThunk(
    'notification/update', 
    async (notification, thunkAPI) => {
      const token = thunkAPI.getState().identityReducer.token.signature;
      const model = new NotificationModel(token)

      try {
          return await model.updateByTag(notification);
          // return await model.getLastNotifications();
      } catch (apiError) {
          return thunkAPI.rejectWithValue(apiError.toJson());
      }
    }
  ),
  updateAll: createAsyncThunk(
    'notification/updateAll', 
    async (notifications, thunkAPI) => {
      const token = thunkAPI.getState().identityReducer.token.signature;
      const model = new NotificationModel(token)

      try {
        notifications.forEach(notification => {
          model.updateByTag(notification);
        });
      } catch (apiError) {
          return thunkAPI.rejectWithValue(apiError.toJson());
      }
    }
  ),
}

const notificationSlice = createSlice({
    name: "notification",
    initialState: notificationAdapter.getInitialState({
      status: 'idle',
      error: null,
    }),
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(notificationApi.fetchLast.fulfilled, (state, action) => {
        state.status = 'notification/fetchLast/succeeded';
        notificationAdapter.setAll(state, action.payload);
      }).addCase(notificationApi.fetchLast.rejected, (state, action) => {
        state.status = 'notification/fetchLast/rejected'
        console.error(action.payload);
        state.error = action.payload
      }).addCase(notificationApi.update.fulfilled, (state, action) => {
        state.status = 'notification/update/succeeded';
        notificationAdapter.removeOne(state, action.payload.id);
        // notificationAdapter.setAll(state, action.payload);
      }).addCase(notificationApi.update.rejected, (state, action) => {
        state.status = 'notification/update/rejected'
        console.error(action.payload);
        state.error = action.payload
      }).addCase(notificationApi.updateAll.fulfilled, (state, action) => {
        state.status = 'notification/updateAll/succeeded';
        notificationAdapter.removeAll(state);
      }).addCase(notificationApi.updateAll.rejected, (state, action) => {
        state.status = 'notification/updateAll/rejected'
        console.error(action.payload);
        state.error = action.payload
      })
    },
  });

  export const selectNotificationByTag = createSelector(
    (state, _) => selectAllNotification(state),
    (_, tag) => tag,
    (notifications, tag) => {
      return (tag !== undefined) 
        ? notifications.find( (notification) => {
            return notification.tag === tag;
          })
        : undefined;
    }
  );

  export const selectRecentNotification = createSelector(
    (state, _) => selectAllNotification(state),
    (_, delay) => delay,
    (notifications, delay) => {
      return notifications.filter( (notification) => {
        return moment().subtract(delay, 'seconds').isBefore(notification.created_at, 'second')
      });
    }
  );

  export const selectAllAggregatedNotification = createSelector(
    (state) => selectAllNotification(state),
    (notifications) => {
      return notifications;
    }
  );



  export const {
    selectAll: selectAllNotification,
    selectById: selectNotificationById,
  } = notificationAdapter.getSelectors((state) => state.notificationReducer)
  
  // export const {  } = notificationSlice.actions;
  
  export default notificationSlice.reducer;