import { OrderCriteria } from '../../../../lib/js-apiclient';
import { ColorApiClient } from './ColorApiClient';
import { WooCommerceColorMapApiClient } from './WooCommerceColorMapApiClient';

export class ColorModel {
    _token = null;
    _colorId = null;

    constructor(token, colorId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._colorId = (colorId !== null && colorId !== undefined) ? colorId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getColors(page, term, sorting) {
        const colorClient = new ColorApiClient(this._token);
        colorClient.setPagingStep(20);

        let orderCriteria = []

        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))

        if(orderCriteria.length > 0) colorClient.orderBy(orderCriteria);
        if(term != null && term.length > 0) colorClient.filterByString(term);

        return await colorClient.fetchPage(page);
    }

    async getColor(id) {
        const colorClient = new ColorApiClient(this._token, id);
        return colorClient.findOne();
    }

    async createColor(shopCode, name) {
        const colorClient = new ColorApiClient(this._token);
        return colorClient.save({
            shop_code: shopCode,
            name: name
        });
    }

    async updateColor(id, shopCode, name) {
        const colorClient = new ColorApiClient(this._token, id);
        return colorClient.save({
            id: id,
            shop_code: shopCode,
            name: name
        });
    }

    async deleteColor(id) {
        const colorClient = new ColorApiClient(this._token, id);
        return colorClient.remove();
    }

    async createMapToWooCommerce(channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceColorMapApiClient(this._token, this._colorId);
        return wcClient.save({
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async updateMapToWooCommerce(id, channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceColorMapApiClient(this._token, this._colorId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceColorMapApiClient(this._token, this._colorId, id);
        wcClient.remove()
    }
}