import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { ChannelLogModel } from '../models/ChannelLogModel';

const channelLogAdapter = createEntityAdapter({
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const channelLogApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    findById: createAsyncThunk(
        'channelLog/findById',
        async ({ channelId, versionId, sorting }, thunkAPI) => {
            const token = channelLogApi._getToken(thunkAPI);
            /** @type {ChannelLogModel} */
            const channelLogModel = new ChannelLogModel(token);

            try {
                return await channelLogModel.getById(channelId, versionId, sorting);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const channelLogSlice = createSlice({
    name: "channelLog",
    initialState: channelLogAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            content: ChannelLogModel.LOG_CONTENT_DIRECTION.desc.value,
        },
        filters: null,
        selectedVersion: ChannelLogModel.LOG_VERSION.current.value,
        showMore: true,
        requestReload: false,
        clearChannelLogListOnResponse: false,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
            channelLogAdapter.removeAll(state);
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        setSelectedVersion(state, action) {
            state.selectedVersion = action.payload;
        },
        setRequestReload(state) {
            // channelLogAdapter.removeAll(state);
            state.requestReload = true;
        },
        instantClearChannelLogList(state) {
            channelLogAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [channelLogApi.findById.fulfilled]: (state, action) => {
            state.status = 'channelLog/findById/succeeded';
            channelLogAdapter.upsertOne(state, action.payload);
            state.requestReload = false;
            state.error = null;
        },
        [channelLogApi.findById.rejected]: (state, action) => {
            state.status = 'channelLog/findById/rejected';
            state.requestReload = false;
            state.error = action.payload;
        },
        [channelLogApi.findById.pending]: (state) => {
            state.status = 'channelLog/findById/pending';
            state.requestReload = false;
        },
    }
});

export const {
    selectById: selectChannelLogById,
    selectAll: selectAllChannelLog,
} = channelLogAdapter.getSelectors((state) => state.channelLogReducer)

export const { setTerm, setSorting, setFilters, setSelectedVersion, setRequestReload, clearChannelLogList, instantClearChannelLogList } = channelLogSlice.actions;

export default channelLogSlice.reducer;
