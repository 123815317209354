import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class SizeApiClient extends AdvancedApiClient {

    static entityType = "Size"

    constructor(token, id) {
        super();

        this.setEntityType(SizeApiClient.entityType)

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/size/{id}")
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/size");
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}