import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { ColorModel } from '../models/color/ColorModel';


const colorAdapter = createEntityAdapter()

export const colorApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'color/fetchAll', 
        async ({page, term, sorting}, thunkAPI) => {
            try {
                const token = colorApi._getToken(thunkAPI);
                const colorModel = new ColorModel(token);

                /** @type {Pager} */
                const pager = await colorModel.getColors(page, term, sorting);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'color/fetchById', 
        async ({id}, thunkAPI) => {
            const token = colorApi._getToken(thunkAPI);
            const colorModel = new ColorModel(token, id);

            try {
                return await colorModel.getColor(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'color/create', 
        async ({shopCode, name}, thunkAPI) => {
            const token = colorApi._getToken(thunkAPI);
            const colorModel = new ColorModel(token);

            try {
                return await colorModel.createColor(shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'color/update', 
        async ({id, shopCode, name}, thunkAPI) => {
            const token = colorApi._getToken(thunkAPI);
            const colorModel = new ColorModel(token);

            try {
                return await colorModel.updateColor(id, shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'color/delete', 
        async (color, thunkAPI) => {
            const token = colorApi._getToken(thunkAPI);
            const colorModel = new ColorModel(token, color.id);

            try {
                await colorModel.deleteColor(color.id);
                return color;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'color/map/woocommerce/create', 
        async ({color, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = colorApi._getToken(thunkAPI);
                const colorModel = new ColorModel(token, color.id);

                const wcColorMap = await colorModel.createMapToWooCommerce(channelId, wcAttributeId, wcTermId);
                return {
                    ...color, 
                    woocommerce_map: color.woocommerce_map.push(wcColorMap)
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'color/map/woocommerce/update', 
        async ({color, mapId, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = colorApi._getToken(thunkAPI);
                const colorModel = new ColorModel(token, color.id);

                const wcColorMap =  await colorModel.updateMapToWooCommerce(mapId, channelId, wcAttributeId, wcTermId);
                return {
                    ...color,
                    woocommerce_map: color.woocommerce_map.map((item) => { return item.id == mapId ? wcColorMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const colorSlice = createSlice({
    name: "color",
    initialState: colorAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedColor: null,
        lastEditColor: null,
        lastDeleteColor: null,
    }),
    reducers: {
        setLastEditColor(state, action) {
            state.lastEditColor = action.payload
        },
        setLastDeleteColor(state, action) {
            state.lastDeleteColor = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedColor = null;
        },
    },
    extraReducers: {
        [colorApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'color/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                colorAdapter.addMany(state, action.payload.entities)
            } else {
                colorAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [colorApi.fetchAll.rejected]: (state, action) => {
            state.status = 'color/fetchAll/rejected'
            state.error = action.payload
        },
        [colorApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'color/fetchById/succeeded'
            colorAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [colorApi.fetchById.rejected]: (state, action) => {
            state.status = 'color/fetchById/rejected'
            state.error = action.payload
        },
        [colorApi.create.fulfilled]: (state, action) => {
            state.status = 'color/create/succeeded'
            state.error = null;
            colorAdapter.addOne(state, action.payload);
            state.lastAddedColor = action.payload;
        },
        [colorApi.create.pending]: (state, action) => {
            state.status = 'color/create/pending';
        },
        [colorApi.create.rejected]: (state, action) => {
            state.status = 'color/create/rejected';
            state.error = action.payload;
        },
        [colorApi.delete.fulfilled]: (state, action) => {
            state.status = 'color/delete/succeeded'
            state.error = null;
            colorAdapter.removeOne(state, action.payload.id)
        },
        [colorApi.delete.rejected]: (state, action) => {
            state.status = 'color/delete/rejected';
            state.error = action.payload;
        },
        [colorApi.update.fulfilled]: (state, action) => {
            state.status = 'color/update/succeeded'
            state.error = null;
            colorAdapter.upsertOne(state, action.payload);
        },
        [colorApi.update.pending]: (state, action) => {
            state.status = 'color/update/pending'
        },
        [colorApi.update.rejected]: (state, action) => {
            state.status = 'color/update/rejected';
            state.error = action.payload;
        },
        [colorApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'color/map/woocommerce/create/succeeded'
            state.error = null;
            colorAdapter.upsertOne(state, action.payload);
        },
        [colorApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'color/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [colorApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'color/map/woocommerce/update/succeeded'
            state.error = null;
            colorAdapter.upsertOne(state, action.payload);
        },
        [colorApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'color/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedColors = createSelector(
    (state) => selectAllColors(state),
    (state) => state.colorReducer.lastAddedColor,
    (colors, lastColor) => {
        return colors.sort((a, b)=>{ 
            return (lastColor && a.id === lastColor.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
  );

export const {
    selectById: selectColorById,
    selectAll: selectAllColors,
} = colorAdapter.getSelectors((state) => state.colorReducer)

export const { setLastEditColor, setLastDeleteColor, setTerm, setSorting } = colorSlice.actions;

export default colorSlice.reducer;
