// import { config } from '../../config';

import { ChannelSyncApiClient } from './ChannelSyncApiClient';

export class ChannelSyncModel {

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getChannelSyncStatusById(channelId) {
        const channelSyncClient = new ChannelSyncApiClient(this._token, channelId);
        return channelSyncClient.findAll();
    }

    async enqueueChannelSyncRequest(channelId, channelProductIds, channelProductStatusList, channelProductVariantIds, channelProductVariantStatusList) {
        const channelSyncClient = new ChannelSyncApiClient(this._token, channelId);

        let cpData = null;
        if(channelProductIds) { cpData = { ids: channelProductIds, sync_status: null } }
        if(channelProductStatusList) { cpData = cpData ? { ...cpData, sync_status: channelProductStatusList } : { ids: null, sync_status: channelProductStatusList } }

        let cpvData = null;
        if(channelProductVariantIds) { cpvData = { ids: channelProductVariantIds, sync_status: null  } }
        if(channelProductVariantStatusList) { cpvData = cpvData ? { ...cpvData, sync_status: channelProductVariantStatusList } : { ids: null, sync_status: channelProductVariantStatusList } }

        // console.log({
        //     channel_products: cpData,
        //     channel_product_variants: cpvData,
        // });

        return channelSyncClient.save({
            channel_products: cpData,
            channel_product_variants: cpvData,
        })
    }
}