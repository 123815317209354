import {v4 as uuidv4} from 'uuid';
import { config } from '../../../config';

import TabManager, {TabDelegate} from '../../../lib/tab-manager';
import { DeviceApiClient } from './DeviceApiClient';
import { IdentityApiClient } from './IdentityApiClient';
import { IdentityConfirmationApiClient } from './IdentityConfirmationApiClient';

export class DeviceModel extends TabDelegate {


    static DeviceType = {
        // web: "web",
        smartphone: "smartphone",
        tablet: "tablet",
        desktop: "desktop"
    }

    static OperatingSystemType = {
        unknown: "unknown",
        android: "android",
        ios: "ios",
        windows: "windows",
        linux: "linux",
        unix: "unix",
        macos: "macos",
    }
    
    static messageType = {
        OPEN: "OPEN",
        REFRESH: "REFRESH",
        LOGIN: "LOGIN",
        LOGOUT: "LOGOUT",
    }

    _tokenIntervalId = null;
    _device = null;
    _token = null;
    _identity = null;

    /** @type {{TabManager}} */
    _tabManager = null;
    _deviceChannelName = config.bundle+"/device";

    _onRefreshCallback = () => {};

    /** @type {DeviceModel} */
    static _instance = null;

    constructor() {
        if (DeviceModel._instance) {
            throw new Error("Singleton classes can't be instantiated more than once.")
        }

        super(); 

        this._getLocalStorageDevice();
        TabManager.getInstance().setDelegate(this);

        if(!TabManager.getInstance().existsChannel(config.tab_channel.device)) {
            TabManager.getInstance().createChannel(config.tab_channel.device);
        }
        TabManager.getInstance().subscribeChannel(config.tab_channel.device, (message, channel_name) => {
            // console.group();
            // console.log("MESSAGE", config.tab_channel.device, channel_name, message);

            const tabToCheck = message.payload.toTab;
            const token = message.payload.token;
            const messageType = message.payload.type;

            if(tabToCheck === null || tabToCheck === undefined || TabManager.getInstance().getTab().uuid === tabToCheck.uuid) {
                if(this._token !== token) {
                    this._token = token;
                    //console.log('TabManager.getInstance().subscribeChannel - token', this._token)
                    
                    this._identity = this._token ? this._parseJWTtoken(this._token.signature).payload.data : null;
                    // console.log("TOKEN UPDATED / "+messageType, this._token, this._identity, tabToCheck);
                    this._onRefreshCallback(messageType);
                }
            }
            // console.groupEnd();
        });

        window.addEventListener('beforeunload', function(event) {
            if(TabManager.getInstance().isLastTab()) {
                this._storeSignature();
            }
        }.bind(this));
    }

    /**
     * 
     * @returns {DeviceModel}
     */
     static getInstance() {
        if(DeviceModel._instance === null) {
            this._instance = new DeviceModel();
        }

        return this._instance;
    }

    setOnRefreshCallback(func) {
        this._onRefreshCallback = func;
    }

    onOpenTab(tab, isElected) {
        if(isElected) {
            const payload = {
                type: DeviceModel.messageType.OPEN,
                toTab: tab,
                token: this._token
            };
            TabManager.getInstance().sendMessage(config.tab_channel.device, payload);
        }
    }

    onCloseTab(tab, isElected) {
        // Do nothing
    }
    
    static getOs() {
        let os = DeviceModel.OperatingSystemType.unknown;

        if (navigator.appVersion.indexOf("Win") !== -1) os = DeviceModel.OperatingSystemType.windows;
        if (navigator.appVersion.indexOf("Mac") !== -1) os = DeviceModel.OperatingSystemType.macos;
        if (navigator.appVersion.indexOf("X11") !== -1) os = DeviceModel.OperatingSystemType.unix;
        if (navigator.appVersion.indexOf("Linux") !== -1) os = DeviceModel.OperatingSystemType.linux;

        return os;
    }

    static getOsVersion(os) {
        let os_version = "web";

        switch (os) {
            case DeviceModel.OperatingSystemType.macos:
                const appVersion = /Mac OS X (10[._\d]+)/.exec(navigator.appVersion);
                if (appVersion !== null) {
                    os_version = appVersion[1].replace(/[_]/g, ".");
                }
                break;
            default:
                os_version = "web";
                break;
        }

        return os_version;
    }

    static getDeviceType() {
        return DeviceModel.DeviceType.desktop;
    }

    static getLanguage() {
        return navigator.language.substring(0, 2);
    }

    _readOrGenDeviceUUID() {
        let uuid = window.localStorage.getItem(DeviceApiClient.entityType.toLowerCase()+"_uuid");

        if(!uuid) {
            uuid = uuidv4();
            window.localStorage.setItem(DeviceApiClient.entityType.toLowerCase()+"_uuid", uuid);
        }

        return uuid;
    }

    _parseJWTtoken(signature) {
        let token = null;
        if(signature) {
            token = {};
            token.raw = signature;
            token.header = JSON.parse(window.atob(signature.split('.')[0]));
            token.payload = JSON.parse(window.atob(signature.split('.')[1]));
        }
        return (token)
    }

    _getLocalStorageDevice() {
        this._device = JSON.parse(window.localStorage.getItem(DeviceApiClient.entityType));
        this._token = JSON.parse(window.localStorage.getItem(DeviceApiClient.entityType+"_token"));
        //console.log('_getLocalStorageDevice - token', this._token)

        if(this._token) {
            const parsedToken = this._parseJWTtoken(this._token.signature) 
            this._identity = parsedToken ? parsedToken.payload.data : null;
            window.localStorage.removeItem(DeviceApiClient.entityType+"_token");
        }
    }

    _setLocalStoreDevice(device) {
        if(device) {
            this._token = device.token;
            //console.log('_setLocalStoreDevice - token', this._token)
            this._identity = this._parseJWTtoken(device.token.signature).payload.data;
            
            delete device.token;
            this._device = device
    
            window.localStorage.setItem(DeviceApiClient.entityType, JSON.stringify(this._device));
        }
    }

    existsValidDevice() {
        return (this._device !== null && this._device !== undefined);
    }

    getDevice() {
        return this._device;
    }

    getToken() {
        return this._token;
    }

    getIdentity() {
        return this._identity;
    }

    _storeSignature() {
        if(this._token) {
            window.localStorage.setItem(DeviceApiClient.entityType+"_token", JSON.stringify(this._token));
        }
    }

    /**
     *  
     * {
            "type": "smartphone",
            "os": "android",
            "os_version": 23,
            "language": "it",
            "enable_notification": true,
            "vendor_id": "dWPEVGJjGNA:APA91bEzLWfVOzbS6btIqDe70Yb7hL..."
        }
     */
    async createDevice({ enable_notification, language }) {
        const entity = {
            type: DeviceModel.getDeviceType(),
            os: DeviceModel.getOs(),
            os_version: DeviceModel.getOsVersion(),
            language: language ? language : DeviceModel.getLanguage(),
            enable_notification: enable_notification ? enable_notification : false,
            vendor_id: this._readOrGenDeviceUUID()
        }
        
        const deviceClient = new DeviceApiClient();
        const devicePromise = deviceClient.save(entity);

        devicePromise.then((device) => {
            this._setLocalStoreDevice(device);
            this._startSignatureMonitoring(config.refreshTokenAdvance); 
        });

        return devicePromise;
    }

    async updateDevice(enable_notification, notification_token = null, language = null) {
        const entity = {
            id: this._device.id,
            type: DeviceModel.getDeviceType(),
            os: DeviceModel.getOs(),
            os_version: DeviceModel.getOsVersion(),
            language: language ? language : DeviceModel.getLanguage(),
            enable_notification: enable_notification ? enable_notification : false,
            vendor_id: this._readOrGenDeviceUUID(),
            notification_token: notification_token
        }
        
        const deviceClient = new DeviceApiClient(this._token.signature, this._device.id);
        return await deviceClient.save(entity);
    }

    /**
     * Reloading device data with asynchronous event is usefull because:
     * 1 - this method will return a Promise like createDevice()
     * 2 - the delay set ensure the device data sync beetween different application browser tab
     * 
     * @return {Promise}
     */
    async reloadDevice() {
        return new Promise(function(resolve, reject) { 
            setTimeout(() => {
                const device = this.getDevice();
                this._startSignatureMonitoring(config.refreshTokenAdvance);

                if(device) {
                    this.refreshToken().then(() => {
                        resolve(device);
                    });
                } else {
                    reject(new Error("Fail to load device"));
                }
            }, 500);
        }.bind(this));
    }

    async refreshToken() {
        if(TabManager.getInstance().isElectedTab()) {
            if(this._token) {
                const device = this.getDevice();
                const identityClient = new IdentityApiClient(device.id);
        
                try {
                    const newToken = await identityClient.edit({
                        token: this._token.refresh
                    });
        
                    this._syncToken(newToken, DeviceModel.messageType.REFRESH);
                    this._onRefreshCallback(DeviceModel.messageType.REFRESH);
                    return newToken;
                } catch(error) {
                    console.error(error)
                    this._forcedLogout(error);
                }
            } else {
                this._forcedLogout("Empty token");
            }
        }
    }

    _syncToken(token, msgType) {
        this._token = token;
        this._identity = token ? this._parseJWTtoken(this._token.signature).payload.data : null;

        const payload = {
            type: msgType,
            toTab: null,
            token: this._token
        };
        TabManager.getInstance().sendMessage(config.tab_channel.device, payload);
    }

    _startSignatureMonitoring(seconds_advance) {
        // console.log("_startSignatureMonitoring", seconds_advance);
        if(this._tokenIntervalId === null) {
            this._tokenIntervalId = setInterval(function() {
                // console.log("_startSignatureMonitoring", "setInterval loop", this._token);
                if(this._token) {
                    const parsedToken = this._parseJWTtoken(this._token.signature);
                    const now = Math.round(+new Date()/1000);
                    const expiredAt = parsedToken.payload.exp;
                    const interval = expiredAt - now - seconds_advance;
                    
                    //console.log("_startSignatureMonitoring", "token will expire in "+(expiredAt - now)+"s");
                    if(interval <= 0) {
                        //console.log('tokenExpired')
                        this.refreshToken();
                    }
                }
            }.bind(this), 60*1000 );
        }
        }

    _forcedLogout(error) {
        console.log("FORCED LOGOUT", error);

        this._token = null;
        //console.log('_forcedLogout - token', this._token)

        this._identity = null;

        this._onRefreshCallback(DeviceModel.messageType.REFRESH);
        this._syncToken(this._token, DeviceModel.messageType.REFRESH);
    }

    async login(username, password) {
        const device = this.getDevice();
        const identityClient = new IdentityApiClient(device.id);

        try {
            const newToken = await identityClient.save({username: username, password: password});
            this._syncToken(newToken, DeviceModel.messageType.LOGIN);
            return null;
        } catch (apiError) {
            throw apiError;
        }
    }

    async logout() {
        const device = this.getDevice();

        // console.log("logout", this._parseJWTtoken(this._token.signature), this._identity);

        const identityClient = new IdentityApiClient(device.id, this._token.signature);

        try {
            await identityClient.remove();
        } catch(error) {
            this._forcedLogout(error);
        };
        
        this._syncToken(null, DeviceModel.messageType.LOGOUT);

        return true;
    }

    async passwordRecovery(username) {
        const device = this.getDevice();
        const identityClient = new IdentityApiClient(device.id);

        try {
            await identityClient.update({
                username: username,
                password: null,
                new_password: null,
            })
        } catch(error) {
            this._forcedLogout(error);
        };
    }

    async passwordUpdate(username, oldPassword, newPassword) {
        const device = this.getDevice();
        const identityClient = new IdentityApiClient(device.id);

        await identityClient.update({
            username: username,
            password: oldPassword,
            new_password: newPassword,
        });
    }

    async identityConfirmation(code) {
        const device = this.getDevice();
        const identityClient = new IdentityConfirmationApiClient(device.id);
        
        await identityClient.save({
            code: code
        });
    }

    async requestNewConfirmationCode(email) {
        const device = this.getDevice();
        const identityClient = new IdentityConfirmationApiClient(device.id);
        
        await identityClient.save({
            email: email
        });
    }


}