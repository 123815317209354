import { OrderCriteria } from '../../../../lib/js-apiclient';
import { SizeApiClient } from './SizeApiClient';
import { FarfetchSizeMapApiClient } from './FarfetchSizeMapApiClient';
import { WooCommerceSizeMapApiClient } from './WooCommerceSizeMapApiClient';
import { IntramirrorSizeMapApiClient } from './IntramirrorSizeMapApiClient';
import { MiintoSizeMapApiClient } from './MiintoSizeMapApiClient';

export class SizeModel {
    _token = null;
    _sizeId = null;

    constructor(token, sizeId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._sizeId = (sizeId !== null && sizeId !== undefined) ? sizeId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getSizes(page, term, sorting) {
        const sizeClient = new SizeApiClient(this._token);
        sizeClient.setPagingStep(20);

        let orderCriteria = []

        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))

        if(orderCriteria.length > 0) sizeClient.orderBy(orderCriteria);
        if(term != null && term.length > 0) sizeClient.filterByString(term);

        return await sizeClient.fetchPage(page);
    }

    async getSize(id) {
        const sizeClient = new SizeApiClient(this._token, id);
        return sizeClient.findOne();
    }

    async createSize(shopCode, name) {
        const sizeClient = new SizeApiClient(this._token);
        return sizeClient.save({
            shop_code: shopCode,
            name: name
        });
    }

    async updateSize(id, shopCode, name) {
        const sizeClient = new SizeApiClient(this._token, id);
        return sizeClient.save({
            id: id,
            shop_code: shopCode,
            name: name
        });
    }

    async deleteSize(id) {
        const sizeClient = new SizeApiClient(this._token, id);
        return sizeClient.remove();
    }

    async createMapToFarfetch(channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName) {
        const ffClient = new FarfetchSizeMapApiClient(this._token, this._sizeId);
        return ffClient.save({
            channel_id: channelId,
            ff_gender_id: ffGenderId,
            ff_category_id: ffCategoryId,
            ff_sizerange_id: ffSizeRangeId,
            ff_sizerange: ffSizeRangeName,
            ff_size_id: ffSizeId,
            ff_size_name: ffSizeName
        })
    }

    async updateMapToFarfetch(id, channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName) {
        const ffClient = new FarfetchSizeMapApiClient(this._token, this._sizeId, id);
        return ffClient.save({
            id: id,
            channel_id: channelId,
            ff_gender_id: ffGenderId,
            ff_category_id: ffCategoryId,
            ff_sizerange_id: ffSizeRangeId,
            ff_sizerange: ffSizeRangeName,
            ff_size_id: ffSizeId,
            ff_size_name: ffSizeName
        })
    }

    async deleteMapToFarfetch(id) {
        const ffClient = new FarfetchSizeMapApiClient(this._token, this._sizeId, id);
        ffClient.remove()
    }

    async createMapToWooCommerce(channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceSizeMapApiClient(this._token, this._sizeId);
        return wcClient.save({
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async updateMapToWooCommerce(id, channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceSizeMapApiClient(this._token, this._sizeId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceSizeMapApiClient(this._token, this._sizeId, id);
        wcClient.remove()
    }

    async createMapToIntramirror(channelId, imName) {
        const imClient = new IntramirrorSizeMapApiClient(this._token, this._sizeId);
        return imClient.save({
            channel_id: channelId,
            im_name: imName
        })
    }

    async updateMapToIntramirror(id, channelId, imName) {
        const imClient = new IntramirrorSizeMapApiClient(this._token, this._sizeId, id);
        return imClient.save({
            id: id,
            channel_id: channelId,
            im_name: imName
        })
    }

    async deleteMapToIntramirror(id) {
        const imClient = new IntramirrorSizeMapApiClient(this._token, this._sizeId, id);
        imClient.remove()
    }

    async createMapToMiinto(channelId, brandId, mntName) {
        const mntClient = new MiintoSizeMapApiClient(this._token, this._sizeId);
        return mntClient.save({
            channel_id: channelId,
            brand_id: brandId,
            mnt_name: mntName
        })
    }

    async updateMapToMiinto(id, channelId, brandId, mntName) {
        const mntClient = new MiintoSizeMapApiClient(this._token, this._sizeId, id);
        return mntClient.save({
            id: id,
            channel_id: channelId,
            brand_id: brandId,
            mnt_name: mntName
        })
    }

    async deleteMapToMiinto(id) {
        const mntClient = new MiintoSizeMapApiClient(this._token, this._sizeId, id);
        mntClient.remove()
    }
}