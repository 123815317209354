import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class FarfetchCollectionApiClient extends AdvancedApiClient {

    static entityType = "FarfetchCollection"

    constructor(token, channelId) {
        super();

        this.setEntityType(FarfetchCollectionApiClient.entityType)
            .setRoute("/api/v1/channel/{id}/farfetch/collection")
            .setRouteParam("id", channelId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}