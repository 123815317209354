import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { FarfetchModel } from '../models/farfetch/FarfetchModel';


const farfetchSizeAdapter = createEntityAdapter({})

export const farfetchSizeApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'farfetch/size/fetchAll', 
        async ({channelId, genderId, categoryId, sizeRangeId, page, term}, thunkAPI) => {
            try {
                const token = farfetchSizeApi._getToken(thunkAPI);
                const farfetchModel = new FarfetchModel(token, channelId);

                const sizes = await farfetchModel.getSizes(genderId, categoryId, sizeRangeId, page, term);
                
                return {
                    hasPrevPage: false,
                    hasNextPage: false,
                    entities: sizes //.splice(0, 5)
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const farfetchSizeSlice = createSlice({
    name: "farfetch/size",
    initialState: farfetchSizeAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearSizeList(state, action) {
            farfetchSizeAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [farfetchSizeApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'farfetch/size/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                farfetchSizeAdapter.addMany(state, action.payload.entities)
            } else {
                farfetchSizeAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [farfetchSizeApi.fetchAll.rejected]: (state, action) => {
            state.status = 'farfetch/size/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllFarfetchSizes,
} = farfetchSizeAdapter.getSelectors((state) => state.farfetchSizeReducer)

export const { clearSizeList } = farfetchSizeSlice.actions;

export default farfetchSizeSlice.reducer;
