import { LHSFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { CategoryApiClient } from './CategoryApiClient';
import { FarfetchCategoryMapApiClient } from './FarfetchCategoryMapApiClient';
import { IntramirrorCategoryMapApiClient } from './IntramirrorCategoryMapApiClient';
import { WooCommerceCategoryMapApiClient } from './WooCommerceCategoryMapApiClient';
import { MiintoCategoryMapApiClient } from './MiintoCategoryMapApiClient';

export class CategoryModel {
    _token = null;
    _categoryId = null;

    constructor(token, categoryId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._categoryId = (categoryId !== null && categoryId !== undefined) ? categoryId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getCategories(page, term, sorting, filter = {}) {
        const categoryClient = new CategoryApiClient(this._token);
        categoryClient.setPagingStep(20);

        let orderCriteria = []

        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))
        if(orderCriteria.length > 0) categoryClient.orderBy(orderCriteria);

        let lhsFilters = []
        if(filter.shop_code) lhsFilters.push( new LHSStringFilter('shop_code', LHSFilter.LHSOperator.eq, filter.shop_code) )
        if(lhsFilters.length > 0) categoryClient.filterByLHSFilter(lhsFilters);
        
        if(term != null && term.length > 0) categoryClient.filterByString(term);

        return await categoryClient.fetchPage(page);
    }

    async getCategory(id) {
        const categoryClient = new CategoryApiClient(this._token, id);
        return categoryClient.findOne();
    }

    async createCategory(shopCode, name) {
        const categoryClient = new CategoryApiClient(this._token);
        return categoryClient.save({
            shop_code: shopCode,
            name: name
        });
    }

    async updateCategory(id, shopCode, name) {
        const categoryClient = new CategoryApiClient(this._token, id);
        return categoryClient.save({
            id: id,
            shop_code: shopCode,
            name: name
        });
    }

    async deleteCategory(id) {
        const categoryClient = new CategoryApiClient(this._token, id);
        return categoryClient.remove();
    }

    async createMapToFarfetch(ffCategory) {
        const ffClient = new FarfetchCategoryMapApiClient(this._token, this._categoryId);
        return ffClient.save(ffCategory)
    }

    async updateMapToFarfetch(id, ffCategory) {
        const ffClient = new FarfetchCategoryMapApiClient(this._token, this._categoryId, id);
        return ffClient.save(ffCategory)
    }

    async deleteMapToFarfetch(id) {
        const ffClient = new FarfetchCategoryMapApiClient(this._token, this._categoryId, id);
        ffClient.remove()
    }

    async createMapToWooCommerce(channelId, wcCategoryId) {
        const wcClient = new WooCommerceCategoryMapApiClient(this._token, this._categoryId);
        return wcClient.save({
            channel_id: channelId,
            wc_category_id: wcCategoryId,
        })
    }

    async updateMapToWooCommerce(id, channelId, wcCategoryId) {
        const wcClient = new WooCommerceCategoryMapApiClient(this._token, this._categoryId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_category_id: wcCategoryId,
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceCategoryMapApiClient(this._token, this._categoryId, id);
        wcClient.remove()
    }

    async createMapToIntramirror(channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId) {
        const imClient = new IntramirrorCategoryMapApiClient(this._token, this._categoryId);
        return imClient.save({
            channel_id: channelId,
            gender_id: genderId,
            im_gender_category_id: imGenderCategoryId,
            im_mid_category_id: imMidCategoryId,
            im_sub_category_id: imSubCategoryId,
        })
    }

    async updateMapToIntramirror(id, channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId) {
        const imClient = new IntramirrorCategoryMapApiClient(this._token, this._categoryId, id);
        return imClient.save({
            id: id,
            channel_id: channelId,
            gender_id: genderId,
            im_gender_category_id: imGenderCategoryId,
            im_mid_category_id: imMidCategoryId,
            im_sub_category_id: imSubCategoryId,
        })
    }

    async deleteMapToIntramirror(id) {
        const imClient = new IntramirrorCategoryMapApiClient(this._token, this._categoryId, id);
        imClient.remove()
    }

    async createMapToMiinto(channelId, mntCategoryId) {
        const mntClient = new MiintoCategoryMapApiClient(this._token, this._categoryId);
        return mntClient.save({
            channel_id: channelId,
            mnt_category_id: mntCategoryId
        })
    }

    async updateMapToMiinto(id, channelId, mntCategoryId) {
        const mntClient = new MiintoCategoryMapApiClient(this._token, this._categoryId, id);
        return mntClient.save({
            id: id,
            channel_id: channelId,
            mnt_category_id: mntCategoryId
        })
    }

    async deleteMapToMiinto(id) {
        const mntClient = new MiintoCategoryMapApiClient(this._token, this._categoryId, id);
        mntClient.remove()
    }
}