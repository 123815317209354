import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { ChannelProductModel } from '../models/ChannelProductModel';

const channelProductAdapter = createEntityAdapter({
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const channelProductApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'channelProduct/fetchAll',
        async ({ page, term, sorting, filters, productId, channelId }, thunkAPI) => {
            try {
                const token = channelProductApi._getToken(thunkAPI);
                /** @type {ChannelProductModel} */
                const channelProductModel = new ChannelProductModel(token);

                if (page !== undefined && page !== null) {
                    /** @type {Pager} */
                    const pager = await channelProductModel.getChannelProducts(
                        page, term, sorting, filters, productId, channelId);
                    
                    let entities = pager.getEntities().map((entity) => {
                        return { ...entity, selected: false }
                    })

                    return {
                        hasPrevPage: pager.hasPrevPage,
                        hasNextPage: pager.hasNextPage,
                        entities: entities //pager.getEntities()
                    }
                } else {
                    return {
                        hasPrevPage: null,
                        hasNextPage: null,
                        entities: await channelProductModel.getChannelProducts(
                            null, term, sorting, filters, productId, channelId),
                    }
                }
            } catch (apiError) {
                console.log(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'channelProduct/update', 
        async ({id, productId, channelId, externalId, extra}, thunkAPI) => {
            const token = channelProductApi._getToken(thunkAPI);
            /** @type {ChannelProductModel} */
            const channelProductModel = new ChannelProductModel(token);

            try {
                return await channelProductModel.updateChannelProductBinding(id, productId, channelId, externalId, extra)
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ), 
    refreshItem: createAsyncThunk(
        'channelProduct/refreshItem',
        async ({ channelProduct }, thunkAPI) => {
            try {
                const token = channelProductApi._getToken(thunkAPI);
                /** @type {ChannelProductModel} */
                const channelProductModel = new ChannelProductModel(token);

                let newItem = await channelProductModel.getChannelProductBindingById(channelProduct.id)
                return {...newItem, selected: channelProduct.selected}

            } catch (apiError) {
                console.log(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const channelProductSlice = createSlice({
    name: "channelProduct",
    initialState: channelProductAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            productId: null,
            productSku: null,
            productName: null,
            externalId: null,
            queueUuid: null,
            variantQueueUuid: null,
            lastAction: null,
            syncStatus: null,
            error: null,
            startSyncAt: null,
            syncedAt: null,
        },
        filters: {
            channelId: null,
            queueUuid: null,
            syncStatus: null,
            productSku: null,
            variantQueueUuid: null,
            variantSyncStatus: null,
            barcode: null,
        },
        showMore: true,
        clearChannelProductListOnResponse: false,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        instantClearChannelProductList(state) {
            channelProductAdapter.removeAll(state);
        },
        clearChannelProductList(state) {
            state.clearChannelProductListOnResponse = true;
        },
        updateChannelProduct(state, action) {
            channelProductAdapter.upsertOne(state, action.payload)
        },
        updateItem(state, action) {
            state.status ='channelProduct/updateItem'
            channelProductAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: {
        [channelProductApi.fetchAll.pending]: (state, action) => {
            state.status = 'channelProduct/fetchAll/pending'
        },
        [channelProductApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'channelProduct/fetchAll/succeeded'

            if (state.clearChannelProductListOnResponse) {
                channelProductAdapter.setAll(state, action.payload.entities)
            } else {
                channelProductAdapter.addMany(state, action.payload.entities)
            }
            state.clearChannelProductListOnResponse = false;
            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [channelProductApi.fetchAll.rejected]: (state, action) => {
            state.status = 'channelProduct/fetchAll/rejected'
            state.error = action.payload
        },
        [channelProductApi.refreshItem.pending]: (state, action) => {
            state.status = 'channelProduct/refreshItem/pending'
        },
        [channelProductApi.refreshItem.fulfilled]: (state, action) => {
            state.status = 'channelProduct/refreshItem/succeeded'
            channelProductAdapter.upsertOne(state, action.payload)
        },
        [channelProductApi.update.pending]: (state, action) => {
            state.status = 'channelProduct/update/pending'
        },
        [channelProductApi.update.fulfilled]: (state, action) => {
            state.status = 'channelProduct/update/succeeded'
            channelProductAdapter.upsertOne(state, action.payload)
        },

    }
});

export const getStatusColor = (status, alpha = false) => {
    let color = null;

    switch (status) {
        case 'success': color = '#00CC74'; break
        case 'error': color = '#CC0000'; break
        case 'pending': color = '#FCB115'; break
        case 'in_progress': color = '#DDDDDD'; break
        case 'no sync': color = '#DDDDDD'; break
        default: color = '#ffffff'; break
    }

    return alpha ? color+"7A" : color;
}

export const {
    selectAll: selectAllChannelProducts,
} = channelProductAdapter.getSelectors((state) => state.channelProductReducer)

export const selectChannelProductsByChannelId = createSelector(
    (state, _) => selectAllChannelProducts(state),
    (_, id) => id,
    (channelProducts, id) => {
        let filteredItems=[];
        for (const channelProduct of channelProducts) {
            if (channelProduct.channel_id === id) {
                filteredItems.push(channelProduct);
            }
        }
        return filteredItems;
    }
)

export const { setTerm, setSorting, setFilters, updateItem, clearChannelProductList, instantClearChannelProductList, updateChannelProduct } = channelProductSlice.actions;

export default channelProductSlice.reducer;
