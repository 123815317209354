import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { GenderModel } from '../models/gender/GenderModel';


const genderAdapter = createEntityAdapter()

export const genderApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'gender/fetchAll', 
        async ({page, term, sorting, filter}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token);

                /** @type {Pager} */
                const pager = await genderModel.getGenders(page, term, sorting, filter);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'gender/fetchById', 
        async ({id}, thunkAPI) => {
            const token = genderApi._getToken(thunkAPI);
            const genderModel = new GenderModel(token, id);

            try {
                return await genderModel.getGender(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'gender/create', 
        async ({shopCode, name}, thunkAPI) => {
            const token = genderApi._getToken(thunkAPI);
            const genderModel = new GenderModel(token);

            try {
                return await genderModel.createGender(shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'gender/update', 
        async ({id, shopCode, name}, thunkAPI) => {
            const token = genderApi._getToken(thunkAPI);
            const genderModel = new GenderModel(token);

            try {
                return await genderModel.updateGender(id, shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'gender/delete', 
        async (gender, thunkAPI) => {
            const token = genderApi._getToken(thunkAPI);
            const genderModel = new GenderModel(token, gender.id);

            try {
                await genderModel.deleteGender(gender.id);
                return gender;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createFarfetchMap: createAsyncThunk(
        'gender/map/farfetch/create', 
        async ({gender, channelId, ffGenderId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const ffGenderMap = await genderModel.createMapToFarfetch(channelId, ffGenderId);
                let farfetch_map = gender.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffGenderMap)

                return { ...gender, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateFarfetchMap: createAsyncThunk(
        'gender/map/farfetch/update', 
        async ({gender, mapId, channelId, ffGenderId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const ffGenderMap =  await genderModel.updateMapToFarfetch(mapId, channelId, ffGenderId);
                return {
                    ...gender, 
                    farfetch_map: gender.farfetch_map.map(function(item) { return item.id == mapId ? ffGenderMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'gender/map/woocommerce/create', 
        async ({gender, channelId, wcTagId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const wcGenderMap = await genderModel.createMapToWooCommerce(channelId, wcTagId);
                let woocommerce_map = gender.woocommerce_map.map(a => {return {...a}})
                woocommerce_map.push(wcGenderMap)

                return { ...gender, woocommerce_map: woocommerce_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'gender/map/woocommerce/update', 
        async ({gender, mapId, channelId, wcTagId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const wcGenderMap =  await genderModel.updateMapToWooCommerce(mapId, channelId, wcTagId);
                return {
                    ...gender,
                    woocommerce_map: gender.woocommerce_map.map((item) => { return item.id == mapId ? wcGenderMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createIntramirrorMap: createAsyncThunk(
        'gender/map/intramirror/create', 
        async ({gender, channelId, imGenderCategoryId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const imGenderMap = await genderModel.createMapToIntramirror(channelId, imGenderCategoryId);
                let intramirror_map = gender.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imGenderMap)

                return { ...gender, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateIntramirrorMap: createAsyncThunk(
        'gender/map/intramirror/update', 
        async ({gender, mapId, channelId, imGenderCategoryId}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const imGenderMap =  await genderModel.updateMapToIntramirror(mapId, channelId, imGenderCategoryId);
                let intramirror_map = gender.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imGenderMap)

                return { ...gender, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createMiintoMap: createAsyncThunk(
        'gender/map/miinto/create', 
        async ({gender, channelId, mntName}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const mntGenderMap = await genderModel.createMapToMiinto(channelId, mntName);
                let miinto_map = gender.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntGenderMap)

                return { ...gender, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateMiintoMap: createAsyncThunk(
        'gender/map/miinto/update', 
        async ({gender, mapId, channelId, mntName}, thunkAPI) => {
            try {
                const token = genderApi._getToken(thunkAPI);
                const genderModel = new GenderModel(token, gender.id);

                const mntGenderMap =  await genderModel.updateMapToMiinto(mapId, channelId, mntName);
                let miinto_map = gender.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntGenderMap)

                return { ...gender, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const genderSlice = createSlice({
    name: "gender",
    initialState: genderAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedGender: null,
        lastEditGender: null,
        lastDeleteGender: null,
    }),
    reducers: {
        setLastEditGender(state, action) {
            state.lastEditGender = action.payload
        },
        setLastDeleteGender(state, action) {
            state.lastDeleteGender = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedGender = null;
        },
        clearGenderList(state, action) {
            genderAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [genderApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'gender/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                genderAdapter.addMany(state, action.payload.entities)
            } else {
                genderAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [genderApi.fetchAll.rejected]: (state, action) => {
            state.status = 'gender/fetchAll/rejected'
            state.error = action.payload
        },
        [genderApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'gender/fetchById/succeeded'
            genderAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [genderApi.fetchById.rejected]: (state, action) => {
            state.status = 'gender/fetchById/rejected'
            state.error = action.payload
        },
        [genderApi.create.fulfilled]: (state, action) => {
            state.status = 'gender/create/succeeded'
            state.error = null;
            genderAdapter.addOne(state, action.payload);
            state.lastAddedGender = action.payload;
        },
        [genderApi.create.pending]: (state, action) => {
            state.status = 'gender/create/pending';
        },
        [genderApi.create.rejected]: (state, action) => {
            state.status = 'gender/create/rejected';
            state.error = action.payload;
        },
        [genderApi.delete.fulfilled]: (state, action) => {
            state.status = 'gender/delete/succeeded'
            state.error = null;
            genderAdapter.removeOne(state, action.payload.id)
        },
        [genderApi.delete.rejected]: (state, action) => {
            state.status = 'gender/delete/rejected';
            state.error = action.payload;
        },
        [genderApi.update.fulfilled]: (state, action) => {
            state.status = 'gender/update/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.update.pending]: (state, action) => {
            state.status = 'gender/update/pending'
        },
        [genderApi.update.rejected]: (state, action) => {
            state.status = 'gender/update/rejected';
            state.error = action.payload;
        },
        [genderApi.createFarfetchMap.pending]: (state, action) => {
            state.status = 'gender/map/farfetch/create/pending';
            state.error = action.payload;
        },
        [genderApi.createFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/farfetch/create/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.createFarfetchMap.rejected]: (state, action) => {
            state.status = 'gender/map/farfetch/create/rejected';
            state.error = action.payload;
        },
        [genderApi.updateFarfetchMap.pending]: (state, action) => {
            state.status = 'gender/map/farfetch/update/pending';
            state.error = action.payload;
        },
        [genderApi.updateFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/farfetch/update/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.updateFarfetchMap.rejected]: (state, action) => {
            state.status = 'gender/map/farfetch/update/rejected';
            state.error = action.payload;
        },
        [genderApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/woocommerce/create/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'gender/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [genderApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/woocommerce/update/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'gender/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
        [genderApi.createIntramirrorMap.pending]: (state, action) => {
            state.status = 'gender/map/intramirror/create/pending';
            state.error = action.payload;
        },
        [genderApi.createIntramirrorMap.rejected]: (state, action) => {
            state.status = 'gender/map/intramirror/create/rejected';
            state.error = action.payload;
        },
        [genderApi.createIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/intramirror/create/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.updateIntramirrorMap.pending]: (state, action) => {
            state.status = 'gender/map/intramirror/update/pending';
            state.error = action.payload;
        },
        [genderApi.updateIntramirrorMap.rejected]: (state, action) => {
            state.status = 'gender/map/intramirror/update/rejected';
            state.error = action.payload;
        },
        [genderApi.updateIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/intramirror/update/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.createMiintoMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/miinto/create/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.createMiintoMap.rejected]: (state, action) => {
            state.status = 'gender/map/miinto/create/rejected';
            state.error = action.payload;
        },
        [genderApi.updateMiintoMap.fulfilled]: (state, action) => {
            state.status = 'gender/map/miinto/update/succeeded'
            state.error = null;
            genderAdapter.upsertOne(state, action.payload);
        },
        [genderApi.updateMiintoMap.rejected]: (state, action) => {
            state.status = 'gender/map/miinto/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedGenders = createSelector(
    (state) => selectAllGenders(state),
    (state) => state.genderReducer.lastAddedGender,
    (genders, lastGender) => {
        return genders.sort((a, b)=>{ 
            return (lastGender && a.id === lastGender.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
)

export const selectGenderByShopCode = createSelector(
    (state, _) => selectAllGenders(state),
    (_, code) => code,
    (items, code) => {
        return items.find( (item) => { 
            return item.shop_code === code 
        });
    }
);

export const {
    selectById: selectGenderById,
    selectAll: selectAllGenders,
} = genderAdapter.getSelectors((state) => state.genderReducer)

export const { setLastEditGender, setLastDeleteGender, setTerm, setSorting, clearGenderList} = genderSlice.actions;

export default genderSlice.reducer;
