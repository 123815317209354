import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ChannelProductVariantApiClient extends AdvancedApiClient {

    static entityType = "ChannelProductVariant"

    constructor(token, cid, id) {
        super();

        this.setEntityType(ChannelProductVariantApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/channel-product/{cid}/variant/{id}")
                .setRouteParam("cid", cid)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/channel-product/{cid}/variant")
                .setRouteParam("cid", cid)
        }

        if (token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}