// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { FarfetchBoxApiClient } from './FarfetchBoxApiClient';
import { FarfetchBrandApiClient } from './FarfetchBrandApiClient';
import { FarfetchCategoryApiClient } from './FarfetchCategoryApiClient';
import { FarfetchGenderApiClient } from './FarfetchGenderApiClient';
import { FarfetchSizeRangeApiClient } from './FarfetchSizeRangeApiClient';
import { FarfetchSizeApiClient } from './FarfetchSizeApiClient';
import { FarfetchSeasonApiClient } from './FarfetchSeasonApiClient';
import { FarfetchCollectionApiClient } from './FarfetchCollectionApiClient';

export class FarfetchModel {

    _token = null;
    _channelId = null;

    constructor(token, channelId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._channelId = (channelId !== null && channelId !== undefined) ? channelId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getBoxes(storeLocation, page, term) {
        const farfetchClient = new FarfetchBoxApiClient(this._token, this._channelId);
        farfetchClient.setPagingStep(5);
        farfetchClient.orderBy([new OrderCriteria('name')]);
        farfetchClient.filterByLHSFilter([new LHSStringFilter('location', LHSFilter.LHSOperator.eq, storeLocation)])

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.fetchPage(page);
    }

    async getBrands(page, term) {
        const farfetchClient = new FarfetchBrandApiClient(this._token, this._channelId);
        farfetchClient.setPagingStep(5);
        farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.fetchPage(page);
    }

    async getGenders(page, term) {
        const farfetchClient = new FarfetchGenderApiClient(this._token, this._channelId);
        farfetchClient.setPagingStep(5);
        farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.fetchPage(page);
    }

    async getCategories(genderId, page, term, parentId) {
        const farfetchClient = new FarfetchCategoryApiClient(this._token, this._channelId, genderId);
        farfetchClient.orderBy([new OrderCriteria('name')]);
        
        if(term != null && term.length > 0) farfetchClient.filterByString(term);
        farfetchClient.filterByLHSFilter([new LHSIntFilter("parent_id", LHSFilter.LHSOperator.eq, parentId ? parentId : "NULL")])
        
        if(page) {
            farfetchClient.setPagingStep(5);
            return await farfetchClient.fetchPage(page);
        } else {
            return await farfetchClient.findAll()
        }
    }

    async getCollections(page, term) {
        const farfetchClient = new FarfetchCollectionApiClient(this._token, this._channelId);
        // farfetchClient.setPagingStep(5);
        farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.findAll()
    }

    async getSizeRanges(brandId, categoryId, page, term) {
        const farfetchClient = new FarfetchSizeRangeApiClient(this._token, this._channelId, categoryId, brandId);
        // farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.findAll()
    }

    async getSizes(genderId, categoryId, sizeRangeId, page, term) {
        const farfetchClient = new FarfetchSizeApiClient(this._token, this._channelId, genderId, categoryId, sizeRangeId);
        // farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.findAll();
    }

    async getSeasons(page, term) {
        const farfetchClient = new FarfetchSeasonApiClient(this._token, this._channelId);
        farfetchClient.setPagingStep(5);
        farfetchClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) farfetchClient.filterByString(term);

        return await farfetchClient.fetchPage(page);
    }
}