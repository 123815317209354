import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { CategoryModel } from '../models/category/CategoryModel';


const categoryAdapter = createEntityAdapter()

export const categoryApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'category/fetchAll', 
        async ({page, term, sorting, filter}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token);

                /** @type {Pager} */
                const pager = await categoryModel.getCategories(page, term, sorting, filter);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'category/fetchById', 
        async ({id}, thunkAPI) => {
            const token = categoryApi._getToken(thunkAPI);
            const categoryModel = new CategoryModel(token, id);

            try {
                return await categoryModel.getCategory(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'category/create', 
        async ({shopCode, name}, thunkAPI) => {
            const token = categoryApi._getToken(thunkAPI);
            const categoryModel = new CategoryModel(token);

            try {
                return await categoryModel.createCategory(shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'category/update', 
        async ({id, shopCode, name}, thunkAPI) => {
            const token = categoryApi._getToken(thunkAPI);
            const categoryModel = new CategoryModel(token);

            try {
                return await categoryModel.updateCategory(id, shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'category/delete', 
        async (category, thunkAPI) => {
            const token = categoryApi._getToken(thunkAPI);
            const categoryModel = new CategoryModel(token, category.id);

            try {
                await categoryModel.deleteCategory(category.id);
                return category;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createFarfetchMap: createAsyncThunk(
        'category/map/farfetch/create', 
        async ({category, ffCategoryMapData}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const ffCategoryMap = await categoryModel.createMapToFarfetch(ffCategoryMapData);
                let farfetch_map = category.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffCategoryMap)

                return { ...category, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateFarfetchMap: createAsyncThunk(
        'category/map/farfetch/update', 
        async ({category, mapId, ffCategoryMapData}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const ffCategoryMap = await categoryModel.updateMapToFarfetch(mapId, ffCategoryMapData)
                let farfetch_map = category.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffCategoryMap)

                return { ...category, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    deleteFarfetchMap: createAsyncThunk(
        'category/map/farfetch/delete', 
        async ({category, mapId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                await categoryModel.deleteMapToFarfetch(mapId)
                let farfetch_map = category.farfetch_map.filter((item) => {return (item.id != mapId) })

                return { ...category, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'category/map/woocommerce/create', 
        async ({category, channelId, wcCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const wcCategoryMap = await categoryModel.createMapToWooCommerce(channelId, wcCategoryId)
                let woocommerce_map = category.woocommerce_map.map(a => {return {...a}})
                woocommerce_map.push(wcCategoryMap)

                return { ...category, woocommerce_map: woocommerce_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'category/map/woocommerce/update', 
        async ({category, mapId, channelId, wcCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const wcCategoryMap =  await categoryModel.updateMapToWooCommerce(mapId, channelId, wcCategoryId);
                let woocommerce_map = category.woocommerce_map.map(a => {return {...a}})
                woocommerce_map.push(wcCategoryMap)
                
                return { ...category, woocommerce_map: woocommerce_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createIntramirrorMap: createAsyncThunk(
        'category/map/intramirror/create', 
        async ({category, channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const imCategoryMap = await categoryModel.createMapToIntramirror(channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId);
                let intramirror_map = category.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imCategoryMap)

                return { ...category, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateIntramirrorMap: createAsyncThunk(
        'category/map/intramirror/update', 
        async ({category, mapId, channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const imCategoryMap =  await categoryModel.updateMapToIntramirror(mapId, channelId, genderId, imGenderCategoryId, imMidCategoryId, imSubCategoryId);
                let intramirror_map = category.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imCategoryMap)

                return { ...category, intramirror_map: intramirror_map }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    deleteIntramirrorMap: createAsyncThunk(
        'category/map/intramirror/delete', 
        async ({category, mapId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                await categoryModel.deleteMapToIntramirror(mapId);
                let intramirror_map = category.intramirror_map.filter(a => {return a.id !== mapId})
                
                return { ...category, intramirror_map: intramirror_map }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createMiintoMap: createAsyncThunk(
        'category/map/miinto/create', 
        async ({category, channelId, mntCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const mntCategoryMap = await categoryModel.createMapToMiinto(channelId, mntCategoryId);
                let miinto_map = category.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntCategoryMap)

                return { ...category, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateMiintoMap: createAsyncThunk(
        'category/map/miinto/update', 
        async ({category, mapId, channelId, mntCategoryId}, thunkAPI) => {
            try {
                const token = categoryApi._getToken(thunkAPI);
                const categoryModel = new CategoryModel(token, category.id);

                const mntCategoryMap =  await categoryModel.updateMapToMiinto(mapId, channelId, mntCategoryId);
                let miinto_map = category.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntCategoryMap)

                return { ...category, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const categorySlice = createSlice({
    name: "category",
    initialState: categoryAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedCategory: null,
        lastEditCategory: null,
        lastDeleteCategory: null,
    }),
    reducers: {
        setLastEditCategory(state, action) {
            state.lastEditCategory = action.payload
        },
        setLastDeleteCategory(state, action) {
            state.lastDeleteCategory = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedCategory = null;
        },
        clearCategoryList(state, action) {
            categoryAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [categoryApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'category/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                categoryAdapter.addMany(state, action.payload.entities)
            } else {
                categoryAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [categoryApi.fetchAll.rejected]: (state, action) => {
            state.status = 'category/fetchAll/rejected'
            state.error = action.payload
        },
        [categoryApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'category/fetchById/succeeded'
            categoryAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [categoryApi.fetchById.rejected]: (state, action) => {
            state.status = 'category/fetchById/rejected'
            state.error = action.payload
        },
        [categoryApi.create.fulfilled]: (state, action) => {
            state.status = 'category/create/succeeded'
            state.error = null;
            categoryAdapter.addOne(state, action.payload);
            state.lastAddedCategory = action.payload;
        },
        [categoryApi.create.pending]: (state, action) => {
            state.status = 'category/create/pending';
        },
        [categoryApi.create.rejected]: (state, action) => {
            state.status = 'category/create/rejected';
            state.error = action.payload;
        },
        [categoryApi.delete.fulfilled]: (state, action) => {
            state.status = 'category/delete/succeeded'
            state.error = null;
            categoryAdapter.removeOne(state, action.payload.id)
        },
        [categoryApi.delete.rejected]: (state, action) => {
            state.status = 'category/delete/rejected';
            state.error = action.payload;
        },
        [categoryApi.update.fulfilled]: (state, action) => {
            state.status = 'category/update/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.update.pending]: (state, action) => {
            state.status = 'category/update/pending'
        },
        [categoryApi.update.rejected]: (state, action) => {
            state.status = 'category/update/rejected';
            state.error = action.payload;
        },
        [categoryApi.createFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'category/map/farfetch/create/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.createFarfetchMap.pending]: (state, action) => {
            state.status = 'category/map/farfetch/create/pending';
        },
        [categoryApi.createFarfetchMap.rejected]: (state, action) => {
            state.status = 'category/map/farfetch/create/rejected';
            state.error = action.payload;
        },
        [categoryApi.updateFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'category/map/farfetch/update/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.updateFarfetchMap.rejected]: (state, action) => {
            state.status = 'category/map/farfetch/update/rejected';
            state.error = action.payload;
        },
        [categoryApi.deleteFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'category/map/farfetch/delete/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.deleteFarfetchMap.rejected]: (state, action) => {
            state.status = 'category/map/farfetch/delete/rejected';
            state.error = action.payload;
        },
        [categoryApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'category/map/woocommerce/create/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'category/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [categoryApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'category/map/woocommerce/update/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'category/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
        [categoryApi.createIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'category/map/intramirror/create/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.createIntramirrorMap.rejected]: (state, action) => {
            state.status = 'category/map/intramirror/create/rejected';
            state.error = action.payload;
        },
        [categoryApi.updateIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'category/map/intramirror/update/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.updateIntramirrorMap.rejected]: (state, action) => {
            state.status = 'category/map/intramirror/update/rejected';
            state.error = action.payload;
        },
        [categoryApi.deleteIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'category/map/intramirror/delete/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.deleteIntramirrorMap.rejected]: (state, action) => {
            state.status = 'category/map/intramirror/delete/rejected';
            state.error = action.payload;
        },
        [categoryApi.createMiintoMap.fulfilled]: (state, action) => {
            state.status = 'category/map/miinto/create/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.createMiintoMap.rejected]: (state, action) => {
            state.status = 'category/map/miinto/create/rejected';
            state.error = action.payload;
        },
        [categoryApi.updateMiintoMap.fulfilled]: (state, action) => {
            state.status = 'category/map/miinto/update/succeeded'
            state.error = null;
            categoryAdapter.upsertOne(state, action.payload);
        },
        [categoryApi.updateMiintoMap.rejected]: (state, action) => {
            state.status = 'category/map/miinto/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedCategories = createSelector(
    (state) => selectAllCategories(state),
    (state) => state.categoryReducer.lastAddedCategory,
    (categorys, lastCategory) => {
        return categorys.sort((a, b)=>{ 
            return (lastCategory && a.id === lastCategory.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
)

export const selectCategoryByShopCode = createSelector(
    (state, _) => selectAllCategories(state),
    (_, code) => code,
    (items, code) => {
        return items.find( (item) => { 
            return item.shop_code === code 
        });
    }
);

export const {
    selectById: selectCategoryById,
    selectAll: selectAllCategories,
} = categoryAdapter.getSelectors((state) => state.categoryReducer)

export const { setLastEditCategory, setLastDeleteCategory, setTerm, setSorting, clearCategoryList } = categorySlice.actions;

export default categorySlice.reducer;
