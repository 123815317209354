// import { config } from '../../config';

import { LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { ChannelApiClient } from './ChannelApiClient';

export class ChannelModel {

    static TYPE_FARFETCH = "Farfetch";
    static TYPE_INTRAMIRROR = "Intramirror";
    static TYPE_MIINTO = "Miinto";
    static TYPE_WOOCOMMERCE = "WooCommerce";

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getChannelById(id) {
        const channelClient = new ChannelApiClient(this._token, id);
        return channelClient.findOne();
    }
    
    async getChannels(term, enabled, synced, validTypes) {
        const channelClient = new ChannelApiClient(this._token);

        let filters = [];
        if(enabled !== null && enabled !== undefined) filters.push(new LHSIntFilter('enabled', LHSIntFilter.LHSOperator.eq, enabled ? 1 : 0));
        if(synced !== null && synced !== undefined) filters.push(new LHSIntFilter('synced', LHSIntFilter.LHSOperator.eq, enabled ? 1 : 0));
        if(validTypes !== null && validTypes !== undefined && Array.isArray(validTypes)) {
            if(validTypes.length == 1) {
                filters.push(new LHSStringFilter('type', LHSStringFilter.LHSOperator.eq, validTypes[0]));
            } else {
                filters.push(new LHSStringFilter('type', LHSStringFilter.LHSOperator.inside, validTypes));
            }
        }
        
        if(filters.length > 0) channelClient.filterByLHSFilter(filters);
        
        if(term != null && term.length > 2) channelClient.filterByString(term);
        channelClient.orderBy([new OrderCriteria("name")])

        return await channelClient.findAll();
    }

    async createChannel(name, type, enabled, config, urlQueue = null) {
        const channelClient = new ChannelApiClient(this._token);

        return channelClient.save({
            name: name,
            type: type,
            enabled: enabled,
            config: config,
            url_queue: urlQueue,
        });
    }

    async deleteChannel(channel) {
        const channelClient = new ChannelApiClient(this._token, channel.id);
        return channelClient.remove();
    }

    async updateChannel(id, name, type, enabled, config, urlQueue = null) {
        const channelClient = new ChannelApiClient(this._token, id);

        return channelClient.save({
            id: id,
            name: name,
            type: type,
            enabled: enabled,
            config: config,
            url_queue: urlQueue,
        });
    }
}