// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { IntramirrorCategoryApiClient } from './IntramirrorCategoryApiClient';

export class IntramirrorModel {

    _token = null;
    _channelId = null;

    constructor(token, channelId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._channelId = (channelId !== null && channelId !== undefined) ? channelId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getCategories(page, term, filter = {}) {
        const intramirrorClient = new IntramirrorCategoryApiClient(this._token, this._channelId);
        // intramirrorClient.setPagingStep(5);
        intramirrorClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) intramirrorClient.filterByString(term);
        if(filter) intramirrorClient.filterByLHSFilter([new LHSIntFilter("parent_id", LHSFilter.LHSOperator.eq, filter.parent_id)])

        // return await intramirrorClient.fetchPage(page);
        return await intramirrorClient.findAll();
    }
}