import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class FarfetchSizeApiClient extends AdvancedApiClient {

    static entityType = "FarfetchSize"

    constructor(token, channelId, genderId, categoryId, sizeRangeId) {
        super();

        this.setEntityType(FarfetchSizeApiClient.entityType)
            .setRoute("/api/v1/channel/{cid}/farfetch/gender/{gid}/category/{catid}/sizerange/{sid}/size")
            .setRouteParam("cid", channelId)
            .setRouteParam("gid", genderId)
            .setRouteParam("catid", categoryId)
            .setRouteParam("sid", sizeRangeId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}