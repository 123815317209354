import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ProductApiClient extends AdvancedApiClient {

    static entityType = "Product"

    constructor(token, type, id) {
        super();

        this.setEntityType(ProductApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/product/{type}/{id}")
                .setRouteParam("type", type)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/product/{type}")
                .setRouteParam("type", type);
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}