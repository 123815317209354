import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class WooCommerceColorMapApiClient extends AdvancedApiClient {

    static entityType = "WooCommerceColorMap"

    constructor(token, colorId, id) {
        super();

        this.setEntityType(WooCommerceColorMapApiClient.entityType)

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/color/{cid}/woocommerce-map/{id}")
                .setRouteParam("cid", colorId)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/color/{cid}/woocommerce-map")
                .setRouteParam("cid", colorId);
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}