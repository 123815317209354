import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { ProductModel } from '../models/ProductModel';


const productVariantAdapter = createEntityAdapter()

export const productVariantApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'product/variant/fetchAll', 
        async ({product, term, sorting, filters}, thunkAPI) => {
            try {
                const token = productVariantApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                return await productModel.getProductVariants(product.id, term, sorting, filters)
            } catch (apiError) {
                console.error(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateVariant: createAsyncThunk(
        'product/variant/update', 
        async ({product, variant}, thunkAPI) => {
            const token = productVariantApi._getToken(thunkAPI);
            const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

            try {
                return await productModel.updateVariant(product.id, variant);
            } catch (apiError) {
                console.error(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const productVariantSlice = createSlice({
    name: "product/variant",
    initialState: productVariantAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            barcode: null,
        },
        filters: null,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        clearAllVariants(state) {
            productVariantAdapter.removeAll(state)
        }
    },
    extraReducers: {
        [productVariantApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'product/variant/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                productVariantAdapter.addMany(state, action.payload)
            } else {
                productVariantAdapter.setAll(state, action.payload)
            }
            state.error = null;
        },
        [productVariantApi.fetchAll.rejected]: (state, action) => {
            state.status = 'product/variant/fetchAll/rejected'
            state.error = action.payload
        },
        [productVariantApi.updateVariant.fulfilled]: (state, action) => {
            state.status = 'product/variant/update/succeeded'
            productVariantAdapter.upsertOne(state, action.payload);
            state.error = null;
        },
        [productVariantApi.updateVariant.rejected]: (state, action) => {
            state.status = 'product/variant/update/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectById: selectProductVariantById,
    selectAll: selectAllProductVariants,
} = productVariantAdapter.getSelectors((state) => state.productVariantReducer)

export const { setFilters, setTerm, setSorting, clearAllVariants } = productVariantSlice.actions;

export default productVariantSlice.reducer;
