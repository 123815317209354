import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { ProductModel } from '../models/ProductModel';


const productAdapter = createEntityAdapter()

export const productApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'product/fetchAll',
        async ({ page, term, sorting, filters }, thunkAPI) => {
            try {
                const token = productApi._getToken(thunkAPI);
                const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

                /** @type {Pager} */
                const pager = await productModel.getProducts(page, term, sorting, filters);

                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'product/fetchById',
        async ({ id }, thunkAPI) => {
            const token = productApi._getToken(thunkAPI);
            const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

            try {
                return await productModel.getProductById(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateProduct: createAsyncThunk(
        'product/update',
        async ({ product }, thunkAPI) => {
            const token = productApi._getToken(thunkAPI);
            const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

            try {
                return await productModel.updateProduct(product.id, product);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateVariant: createAsyncThunk(
        'product/update/variant',
        async ({ product, variant }, thunkAPI) => {
            const token = productApi._getToken(thunkAPI);
            const productModel = new ProductModel(token, ProductModel.TYPE_FASHION);

            try {
                let qtaTot = product.qta_tot;
                const updatedVariant = await productModel.updateVariant(product.id, variant);
                const variants = product.variants.map((v) => {
                    if (v.id == updatedVariant.id) {
                        qtaTot = qtaTot + (updatedVariant.qta - v.qta)
                    }
                    const vrt = v.id != updatedVariant.id ? v : updatedVariant
                    return vrt;
                })

                return { ...product, qta_tot: qtaTot, variants: variants }

            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const productSlice = createSlice({
    name: "product",
    initialState: productAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            sku: null,
            name: null,
            shortDescription: null,
            seasonCode: null,
            genderCode: null,
            categoryCode: null,
            brandCode: null,
            colorCode: null,
            statusName: null,
            qtaTot: null,
        },
        filters: {
            sku: null,
            barcode: null,
            brandCode: null,
            categoryCode: null,
            genderCode: null,
            seasonCode: null,
            statusName: null,
            coverId: null,
            cpFilter: null,
            cpvFilter: null,
            error: null,
        },
        showMore: true,
        clearProductListOnResponse: false,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        updateLocalProduct(state, action) {
            productAdapter.upsertOne(state, action.payload);
        },
        clearProductList(state) {
            // productAdapter.removeAll(state)
            state.clearProductListOnResponse = true;
        }
    },
    extraReducers: {
        [productApi.fetchAll.pending]: (state, action) => {
            state.status = 'product/fetchAll/pending'
        },
        [productApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'product/fetchAll/succeeded'

            if (state.clearProductListOnResponse) {
                productAdapter.setAll(state, action.payload.entities)
            } else {
                productAdapter.addMany(state, action.payload.entities)
            }
            state.clearProductListOnResponse = false;
            // if(action.payload.hasPrevPage) {
            //     productAdapter.addMany(state, action.payload.entities)
            // } else {
            //     productAdapter.setAll(state, action.payload.entities)
            // }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [productApi.fetchAll.rejected]: (state, action) => {
            state.status = 'product/fetchAll/rejected'
            state.error = action.payload
        },
        [productApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'product/fetchById/succeeded'
            productAdapter.upsertOne(state, action.payload);
            state.error = null;
        },
        [productApi.fetchById.rejected]: (state, action) => {
            state.status = 'product/fetchById/rejected'
            state.error = action.payload
        },
        [productApi.updateProduct.fulfilled]: (state, action) => {
            state.status = 'product/update/succeeded'
            productAdapter.upsertOne(state, action.payload);
            state.error = null;
        },
        [productApi.updateProduct.rejected]: (state, action) => {
            state.status = 'product/update/rejected'
            state.error = action.payload
        },
        [productApi.updateVariant.fulfilled]: (state, action) => {
            state.status = 'product/update/variant/succeeded'
            productAdapter.upsertOne(state, action.payload);
            state.error = null;
        },
        [productApi.updateVariant.rejected]: (state, action) => {
            state.status = 'product/update/variant/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectById: selectProductById,
    selectAll: selectAllProducts,
} = productAdapter.getSelectors((state) => state.productReducer)

export const { setFilters, setTerm, setSorting, updateLocalProduct, clearProductList } = productSlice.actions;

export const selectSortedVariants = createSelector(
    (state, id) => selectProductById(state, id),
    (product) => {
        // console.log('selectSortedVariants',product.variants);
        let variants = [...product.variants]

        return variants.sort((a, b) => {
            return a.weight - b.weight
        });
    }
);

export default productSlice.reducer;
