import { OrderCriteria } from "../../../lib/js-apiclient";
import { ChannelLogApiClient } from "./ChannelLogApiClient";

export class ChannelLogModel {
    static LOG_VERSION = {
        current: { value: 1, name: 'current log' },
        oneDayBefore: { value: 2, name: 'one day before' },
        twoDayBefore: { value: 3, name: 'two day before' },
    }

    static LOG_CONTENT_DIRECTION = {
        asc: { value: 'ASC', name: 'ASC' },
        desc: { value: 'DESC', name: 'DESC' },
    }

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getById(channelId, versionId, sorting) {
        const channelLogClient = new ChannelLogApiClient(this._token, channelId, versionId);

        if (sorting) {
            let orderCriteria = [];
            if (sorting.content !== null) orderCriteria.push((new OrderCriteria('content', sorting.content)).setWeight(10));
            if (orderCriteria.length > 0) {
                channelLogClient.orderBy(orderCriteria);
            }
        }

        return channelLogClient.findOne();
    }
}