import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { MiintoModel } from '../models/miinto/MiintoModel';


const miintoCategoryAdapter = createEntityAdapter({})

export const miintoCategoryApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'miinto/category/fetchAll', 
        async ({page, term}, thunkAPI) => {
            try {
                const token = miintoCategoryApi._getToken(thunkAPI);
                const miintoModel = new MiintoModel(token);

                const result = await miintoModel.getCategories(page, term);
                
                return {
                    hasPrevPage: page ? result.hasPrevPage : true,
                    hasNextPage: page ? result.hasNextPage : true,
                    entities: page ? result.getEntities() : result
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const miintoCategorySlice = createSlice({
    name: "miinto/category",
    initialState: miintoCategoryAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearCategoryList(state, action) {
            miintoCategoryAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [miintoCategoryApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'miinto/category/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                miintoCategoryAdapter.addMany(state, action.payload.entities)
            } else {
                miintoCategoryAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [miintoCategoryApi.fetchAll.rejected]: (state, action) => {
            state.status = 'miinto/category/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllMiintoCategories,
} = miintoCategoryAdapter.getSelectors((state) => state.miintoCategoryReducer)

export const { clearCategoryList } = miintoCategorySlice.actions;

export default miintoCategorySlice.reducer;
