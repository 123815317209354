// import { config } from '../../config';

import { OrderCriteria } from '../../../lib/js-apiclient';
import { UserApiClient } from './UserApiClient';
import { UserMediaApiClient } from './UserMediaApiClient';

export class UserModel {

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getUser(uid) {
        const userClient = new UserApiClient(this._token, uid);
        return userClient.findOne();
    }

    async createUser(firstname, lastname, tagname, email, password, privacy, terms) {
        const userClient = new UserApiClient();

        return userClient.save({
            first_name: firstname,
            last_name: lastname,
            tag_name: tagname,
            email: email,
            password: password,
            privacy: privacy,
            terms: terms,
        });
    }

    async updateUser(uid, firstname, lastname, tagname, email, phoneCountry, phoneNumber) {
        const userClient = new UserApiClient(this._token, uid);

        return userClient.save({
            id: uid,
            first_name: firstname,
            last_name: lastname,
            tag_name: tagname,
            email: email,
            phone_country: phoneCountry,
            phone_number: phoneNumber,
            privacy: true,
            terms: true,
            locked: false,
        });
    }

    async lock(user) {
        const userClient = new UserApiClient(this._token, user.id);
        userClient.remove()
        return { ...user, locked: true }
    }

    async unlock(user) {
        const userClient = new UserApiClient(this._token, user.id);

        return userClient.save({ 
            id: user.id,
            email: user.email, 
            privacy: true,
            terms: true,
            locked: false,
        });
    }

    async createUserMedia(user, file) {
        const userClient = new UserApiClient(this._token, user.id);

        const userMediaClient = new UserMediaApiClient(this._token, user.id);
        userMediaClient.setContentType("multipart/form-data")

        const media = await userMediaClient.save({
            media: file,
        });

        userClient.save({
            id: user.id,
            avatar_media_id: media.id,
            first_name: user.firstname,
            last_name: user.lastname,
            email: user.email,
            phone_country: user.phoneCountry,
            phone_number: user.phoneNumber,
            privacy: true,
            terms: true,
        });

        return media;
    }
    
    async getUsers(page, term, sorting) {
        const userClient = new UserApiClient(this._token);
        userClient.setPagingStep(10);

        let orderCriteria = []
        
        if(sorting.first_name !== null) orderCriteria.push( (new OrderCriteria('first_name', sorting.first_name)).setWeight(20) )
        if(sorting.last_name !== null) orderCriteria.push((new OrderCriteria('last_name', sorting.last_name).setWeight(30)))
        if(sorting.email !== null) orderCriteria.push((new OrderCriteria('email', sorting.email).setWeight(40)))

        if(orderCriteria.length > 0) userClient.orderBy(orderCriteria);

        if(term != null && term.length > 2) userClient.filterByString(term);

        return await userClient.fetchPage(page);
    }
}