import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { AvgAnalyticsModel } from '../models/AvgAnalyticsModel';

const avgAnalyticsSeasonAdapter = createEntityAdapter();

export const avgAnalyticsSeasonApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'avgAnalyticsSeason/fetchAll',
        async ({ page, term, sorting, filters, avgType }, thunkAPI) => {
            try {
                const token = avgAnalyticsSeasonApi._getToken(thunkAPI);
                const avgAnalyticsModel = new AvgAnalyticsModel(token, AvgAnalyticsModel.TYPE_FASHION);

                /** @type {Pager} */
                const pager = await avgAnalyticsModel.getAnalytics(
                    page, term, sorting, filters, avgType, AvgAnalyticsModel.TAXONOMY.season);

                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const avgAnalyticsSeasonSlice = createSlice({
    name: "avgAnalyticsSeason",
    initialState: avgAnalyticsSeasonAdapter.getInitialState({
        status: 'idle',
        error: null,
        avgType: null,
        term: null,
        sorting: null,
        filters: {
            seasonCode: null,
        },
        showMore: true,
        clearListOnResponse: false,
    }),
    reducers: {
        setAvgType(state, action) {
            state.avgType = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        clearAvgAnalytics(state) {
            state.clearListOnResponse = true
        },
    },
    extraReducers: {
        [avgAnalyticsSeasonApi.fetchAll.pending]: (state, action) => {
            state.status = 'avgAnalyticsSeason/fetchAll/pending';
        },
        [avgAnalyticsSeasonApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'avgAnalyticsSeason/fetchAll/succeeded';
            if(state.clearListOnResponse) {
                avgAnalyticsSeasonAdapter.setAll(state, action.payload.entities)
            } else {
                avgAnalyticsSeasonAdapter.addMany(state, action.payload.entities)
            }
            state.clearListOnResponse = false
            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [avgAnalyticsSeasonApi.fetchAll.rejected]: (state, action) => {
            state.status = 'avgAnalyticsSeason/fetchAll/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectById: selectAnalyticsById,
    selectAll: selectAllAnalytics,
} = avgAnalyticsSeasonAdapter.getSelectors((state) => state.avgAnalyticsSeasonReducer)

export const { setAvgType, setSorting, setFilters, clearAvgAnalytics } = avgAnalyticsSeasonSlice.actions;

export default avgAnalyticsSeasonSlice.reducer;