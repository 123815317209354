import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class FarfetchCategoryApiClient extends AdvancedApiClient {

    static entityType = "FarfetchCategory"

    constructor(token, channelId, genderId) {
        super();

        this.setEntityType(FarfetchCategoryApiClient.entityType)
            .setRoute("/api/v1/channel/{cid}/farfetch/gender/{gid}/category")
            .setRouteParam("cid", channelId)
            .setRouteParam("gid", genderId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}