import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class TermApiClient extends AdvancedApiClient {

    static entityType = "WooCommerceTerm"

    constructor(token, channelId, attributeId) {
        super();

        this.setEntityType(TermApiClient.entityType)
            .setRoute("/api/v1/channel/{cid}/woocommerce/attribute/{id}/term")
            .setRouteParam("cid", channelId)
            .setRouteParam("id", attributeId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}