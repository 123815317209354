import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class AvgAnalyticsApiClient extends AdvancedApiClient {

    static entityType = "AvgAnalytics";

    constructor(token, avg_type, product_type, taxonomy) {
        super();

        this.setEntityType(AvgAnalyticsApiClient.entityType);

        if (taxonomy !== undefined && taxonomy !== null) {
            this.setRoute("/api/v1/analytics/avg_prices/{avg_type}/product/{product_type}/{taxonomy}")
                .setRouteParam("avg_type", avg_type)
                .setRouteParam("product_type", product_type)
                .setRouteParam("taxonomy", taxonomy);
        } else {
            this.setRoute("/api/v1/analytics/avg_prices/{avg_type}/product/{product_type}")
                .setRouteParam("avg_type", avg_type)
                .setRouteParam("product_type", product_type);
        }

        if (token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token));
        }
    }
}