import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { BrandModel } from '../models/brand/BrandModel';


const brandAdapter = createEntityAdapter()

export const brandApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'brand/fetchAll', 
        async ({page, term, sorting, filter}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token);

                /** @type {Pager} */
                const pager = await brandModel.getBrands(page, term, sorting, filter);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'brand/fetchById', 
        async ({id}, thunkAPI) => {
            const token = brandApi._getToken(thunkAPI);
            const brandModel = new BrandModel(token, id);

            try {
                return await brandModel.getBrand(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'brand/create', 
        async ({shopCode, name}, thunkAPI) => {
            const token = brandApi._getToken(thunkAPI);
            const brandModel = new BrandModel(token);

            try {
                return await brandModel.createBrand(shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'brand/update', 
        async ({id, shopCode, name}, thunkAPI) => {
            const token = brandApi._getToken(thunkAPI);
            const brandModel = new BrandModel(token);

            try {
                return await brandModel.updateBrand(id, shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'brand/delete', 
        async (brand, thunkAPI) => {
            const token = brandApi._getToken(thunkAPI);
            const brandModel = new BrandModel(token, brand.id);

            try {
                await brandModel.deleteBrand(brand.id);
                return brand;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createFarfetchMap: createAsyncThunk(
        'brand/map/farfetch/create', 
        async ({brand, channelId, ffBrandId}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const ffBrandMap = await brandModel.createMapToFarfetch(channelId, ffBrandId);
                let farfetch_map = brand.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffBrandMap)

                return { ...brand, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateFarfetchMap: createAsyncThunk(
        'brand/map/farfetch/update', 
        async ({brand, mapId, channelId, ffBrandId}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const ffBrandMap =  await brandModel.updateMapToFarfetch(mapId, channelId, ffBrandId);
                let farfetch_map = brand.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffBrandMap)

                return { ...brand, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'brand/map/woocommerce/create', 
        async ({brand, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const wcBrandMap = await brandModel.createMapToWooCommerce(channelId, wcAttributeId, wcTermId)
                let woocommerce_map = brand.woocommerce_map.map(a => {return {...a}})
                woocommerce_map.push(wcBrandMap)

                return { ...brand, woocommerce_map: woocommerce_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'brand/map/woocommerce/update', 
        async ({brand, mapId, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const wcBrandMap =  await brandModel.updateMapToWooCommerce(mapId, channelId, wcAttributeId, wcTermId);
                return {
                    ...brand,
                    woocommerce_map: brand.woocommerce_map.map((item) => { return item.id == mapId ? wcBrandMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createIntramirrorMap: createAsyncThunk(
        'brand/map/intramirror/create', 
        async ({brand, channelId, imName}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const imBrandMap = await brandModel.createMapToIntramirror(channelId, imName);
                let intramirror_map = brand.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imBrandMap)

                return { ...brand, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateIntramirrorMap: createAsyncThunk(
        'brand/map/intramirror/update', 
        async ({brand, mapId, channelId, imName}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const imBrandMap =  await brandModel.updateMapToIntramirror(mapId, channelId, imName);
                let intramirror_map = brand.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imBrandMap)

                return { ...brand, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createMiintoMap: createAsyncThunk(
        'brand/map/miinto/create', 
        async ({brand, channelId, mntName}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const mntBrandMap = await brandModel.createMapToMiinto(channelId, mntName);
                let miinto_map = brand.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntBrandMap)

                return { ...brand, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateMiintoMap: createAsyncThunk(
        'brand/map/miinto/update', 
        async ({brand, mapId, channelId, mntName}, thunkAPI) => {
            try {
                const token = brandApi._getToken(thunkAPI);
                const brandModel = new BrandModel(token, brand.id);

                const mntBrandMap =  await brandModel.updateMapToMiinto(mapId, channelId, mntName);
                let miinto_map = brand.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntBrandMap)

                return { ...brand, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const brandSlice = createSlice({
    name: "brand",
    initialState: brandAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedBrand: null,
        lastEditBrand: null,
        lastDeleteBrand: null,
    }),
    reducers: {
        setLastEditBrand(state, action) {
            state.lastEditBrand = action.payload
        },
        setLastDeleteBrand(state, action) {
            state.lastDeleteBrand = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedBrand = null;
        },
        clearBrandList(state, action) {
            brandAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [brandApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'brand/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                brandAdapter.addMany(state, action.payload.entities)
            } else {
                brandAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [brandApi.fetchAll.rejected]: (state, action) => {
            state.status = 'brand/fetchAll/rejected'
            state.error = action.payload
        },
        [brandApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'brand/fetchById/succeeded'
            brandAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [brandApi.fetchById.rejected]: (state, action) => {
            state.status = 'brand/fetchById/rejected'
            state.error = action.payload
        },
        [brandApi.create.fulfilled]: (state, action) => {
            state.status = 'brand/create/succeeded'
            state.error = null;
            brandAdapter.addOne(state, action.payload);
            state.lastAddedBrand = action.payload;
        },
        [brandApi.create.pending]: (state, action) => {
            state.status = 'brand/create/pending';
        },
        [brandApi.create.rejected]: (state, action) => {
            state.status = 'brand/create/rejected';
            state.error = action.payload;
        },
        [brandApi.delete.fulfilled]: (state, action) => {
            state.status = 'brand/delete/succeeded'
            state.error = null;
            brandAdapter.removeOne(state, action.payload.id)
        },
        [brandApi.delete.rejected]: (state, action) => {
            state.status = 'brand/delete/rejected';
            state.error = action.payload;
        },
        [brandApi.update.fulfilled]: (state, action) => {
            state.status = 'brand/update/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.update.pending]: (state, action) => {
            state.status = 'brand/update/pending'
        },
        [brandApi.update.rejected]: (state, action) => {
            state.status = 'brand/update/rejected';
            state.error = action.payload;
        },
        [brandApi.createFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/farfetch/create/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.createFarfetchMap.rejected]: (state, action) => {
            state.status = 'brand/map/farfetch/create/rejected';
            state.error = action.payload;
        },
        [brandApi.updateFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/farfetch/update/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.updateFarfetchMap.rejected]: (state, action) => {
            state.status = 'brand/map/farfetch/update/rejected';
            state.error = action.payload;
        },
        [brandApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/woocommerce/create/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'brand/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [brandApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/woocommerce/update/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'brand/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
        [brandApi.createIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/intramirror/create/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.createIntramirrorMap.rejected]: (state, action) => {
            state.status = 'brand/map/intramirror/create/rejected';
            state.error = action.payload;
        },
        [brandApi.updateIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/intramirror/update/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.updateIntramirrorMap.rejected]: (state, action) => {
            state.status = 'brand/map/intramirror/update/rejected';
            state.error = action.payload;
        },
        [brandApi.createMiintoMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/miinto/create/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.createMiintoMap.rejected]: (state, action) => {
            state.status = 'brand/map/miinto/create/rejected';
            state.error = action.payload;
        },
        [brandApi.updateMiintoMap.fulfilled]: (state, action) => {
            state.status = 'brand/map/miinto/update/succeeded'
            state.error = null;
            brandAdapter.upsertOne(state, action.payload);
        },
        [brandApi.updateMiintoMap.rejected]: (state, action) => {
            state.status = 'brand/map/miinto/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedBrands = createSelector(
    (state) => selectAllBrands(state),
    (state) => state.brandReducer.lastAddedBrand,
    (brands, lastBrand) => {
        return brands.sort((a, b)=>{ 
            return (lastBrand && a.id === lastBrand.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
)

export const selectBrandByShopCode = createSelector(
    (state, _) => selectAllBrands(state),
    (_, code) => code,
    (items, code) => {
        return items.find( (item) => { 
            return item.shop_code === code 
        });
    }
);

export const {
    selectById: selectBrandById,
    selectAll: selectAllBrands,
} = brandAdapter.getSelectors((state) => state.brandReducer)

export const { setLastEditBrand, setLastDeleteBrand, setTerm, setSorting, clearBrandList } = brandSlice.actions;

export default brandSlice.reducer;
