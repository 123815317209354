import { LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { ChannelProductApiClient } from './ChannelProductApiClient';

export class ChannelProductModel {
    static SYNC_STATUS = {
        success: 'success',
        pending: 'pending',
        error: 'error',
    }

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getChannelProductBindingById(id) {
        const channelProductClient = new ChannelProductApiClient(this._token, id);
        return channelProductClient.findOne();
    }

    async getChannelProducts(page, term, sorting, filters, productId, channelId) {
        const channelProductClient = new ChannelProductApiClient(this._token);
        channelProductClient.setPagingStep(10);

        if (term != null && term.length > 2) channelProductClient.filterByString(term);

        if (sorting) {
            let orderCriteria = [];
            if (sorting.id !== null) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10))
            if (sorting.productId !== null) orderCriteria.push((new OrderCriteria('product_id', sorting.productId)).setWeight(20))
            if (sorting.productSku !== null) orderCriteria.push((new OrderCriteria('product_sku', sorting.productSku)).setWeight(30))
            if (sorting.productName !== null) orderCriteria.push((new OrderCriteria('product_name', sorting.productName)).setWeight(40))
            if (sorting.externalId !== null) orderCriteria.push((new OrderCriteria('external_id', sorting.externalId)).setWeight(50))
            if (sorting.queueUuid !== null) orderCriteria.push((new OrderCriteria('queue_uuid', sorting.queueUuid)).setWeight(60))
            if (sorting.lastAction !== null) orderCriteria.push((new OrderCriteria('last_action', sorting.lastAction)).setWeight(70))
            if (sorting.syncStatus !== null) orderCriteria.push((new OrderCriteria('sync_status', sorting.syncStatus)).setWeight(80))
            if (sorting.error !== null) orderCriteria.push((new OrderCriteria('error', sorting.error)).setWeight(90))
            if (sorting.startSyncAt !== null) orderCriteria.push((new OrderCriteria('start_sync_at', sorting.startSyncAt)).setWeight(100))
            if (sorting.syncedAt !== null) orderCriteria.push((new OrderCriteria('synced_at', sorting.syncedAt)).setWeight(110))
            if (orderCriteria.length > 0) {
                channelProductClient.orderBy(orderCriteria);
            } else {
                channelProductClient.orderBy([new OrderCriteria("channel_name")]);
            }
        }

        let filterCriteria = [];
        if (productId !== null && productId !== undefined) filterCriteria.push(new LHSIntFilter('product_id', LHSIntFilter.LHSOperator.eq, productId));
        if (channelId !== null && channelId !== undefined) filterCriteria.push(new LHSIntFilter('channel_id', LHSIntFilter.LHSOperator.eq, channelId));
        if (filters) {
            if (filters.channelId !== null && filters.channelId !== undefined) filterCriteria.push(new LHSIntFilter('channel_id', LHSIntFilter.LHSOperator.eq, filters.channelId));
            if (filters.queueUuid !== null && filters.queueUuid !== undefined) filterCriteria.push(new LHSStringFilter('queue_uuid', LHSIntFilter.LHSOperator.eq, filters.queueUuid));
            if (filters.productSku !== null && filters.productSku !== undefined) filterCriteria.push(new LHSStringFilter('product_sku', LHSIntFilter.LHSOperator.eq, filters.productSku));
            if (filters.syncStatus !== null && filters.syncStatus !== undefined) filterCriteria.push(new LHSStringFilter('sync_status', LHSIntFilter.LHSOperator.eq, filters.syncStatus));
            if (filters.variantQueueUuid !== null && filters.variantQueueUuid !== undefined) filterCriteria.push(new LHSStringFilter('variant_queue_uuid', LHSIntFilter.LHSOperator.like, filters.variantQueueUuid));
            if (filters.variantSyncStatus !== null && filters.variantSyncStatus !== undefined) filterCriteria.push(new LHSStringFilter('variant_queue_sync_status', LHSIntFilter.LHSOperator.like, filters.variantSyncStatus));
            if (filters.barcode !== null && filters.barcode !== undefined) filterCriteria.push(new LHSStringFilter('barcode', LHSIntFilter.LHSOperator.like, filters.barcode));
            if (filters.externalId !== null && filters.externalId !== undefined) filterCriteria.push(new LHSStringFilter('external_id', LHSIntFilter.LHSOperator.like, filters.externalId));
            if (filters.error !== null && filters.error !== undefined) filterCriteria.push(new LHSStringFilter('error', LHSIntFilter.LHSOperator.like, filters.error));
            if (filters.variantExternalId !== null && filters.variantExternalId !== undefined) filterCriteria.push(new LHSStringFilter('variant_external_id', LHSIntFilter.LHSOperator.like, filters.variantExternalId));
            if (filters.variantError !== null && filters.variantError !== undefined) filterCriteria.push(new LHSStringFilter('variant_error', LHSIntFilter.LHSOperator.like, filters.variantError));
        }
        if (filterCriteria.length > 0) channelProductClient.filterByLHSFilter(filterCriteria);

        if (page !== null) {
            // console.log("fetchPage");
            return await channelProductClient.fetchPage(page);
        } else {
            // console.log("findAll");
            return await channelProductClient.findAll();
        }
    }

    async createChannelProductBinding(productId, channelId) {
        const channelProductClient = new ChannelProductApiClient(this._token);

        return channelProductClient.save({
            product_id: productId,
            channel_id: channelId,
        });
    }

    async updateChannelProductBinding(id, productId, channelId, externalId, extra) {
        const channelProductClient = new ChannelProductApiClient(this._token, id);

        let entity = {
            id: id,
            product_id: productId,
            channel_id: channelId,
        }

        if(externalId > 0) {
            entity = { ...entity, external_id: externalId }
        } else {
            entity = { ...entity, extra: extra }
        }

        return channelProductClient.save(entity);
    }

    async deleteChannelProductBinding(channelProduct) {
        const channelProductClient = new ChannelProductApiClient(this._token, channelProduct.id);
        return channelProductClient.remove();
    }
}