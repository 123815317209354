import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { ChannelProductVariantModel } from '../models/ChannelProductVariantModel';

const channelProductVariantAdapter = createEntityAdapter({
    // sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const channelProductVariantApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'channelProductVariant/fetchAll',
        async ({ page, term, sorting, filters, channelProductId }, thunkAPI) => {
            // console.log({ page, term, sorting, filters, channelProductId })
            try {
                const token = channelProductVariantApi._getToken(thunkAPI);
                /** @type {ChannelProductVariantModel} */
                const channelProductVariantModel = new ChannelProductVariantModel(token, channelProductId);

                if (page !== undefined && page !== null) {
                    /** @type {Pager} */
                    const pager = await channelProductVariantModel.getChannelProductVariants(
                        page, term, sorting, filters);

                    return {
                        hasPrevPage: pager.hasPrevPage,
                        hasNextPage: pager.hasNextPage,
                        entities: pager.getEntities()
                    }
                } else {

                    let entities = await channelProductVariantModel.getChannelProductVariants(
                        null, term, sorting, filters)
                        
                    entities = entities.map((entity) => {
                        return { ...entity, selected: false }
                    })

                    return {
                        hasPrevPage: null,
                        hasNextPage: null,
                        entities: entities
                    }
                }
            } catch (apiError) {
                console.error(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    refreshItem: createAsyncThunk(
        'channelProductVariant/refreshItem',
        async ({ channelProductVariant }, thunkAPI) => {
            try {
                const token = channelProductVariantApi._getToken(thunkAPI);
                /** @type {ChannelProductVariantModel} */
                const channelProductVariantModel = new ChannelProductVariantModel(token, channelProductVariant.channel_product_id);

                let newItem = await channelProductVariantModel.getChannelProductVariantBindingById(channelProductVariant.id)
                return {...newItem, selected: channelProductVariant.selected}

            } catch (apiError) {
                console.log(apiError);
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const channelProductVariantSlice = createSlice({
    name: "channelProductVariant",
    initialState: channelProductVariantAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            channelId: null,
            productId: null,
            productVariantId: null,
            externalId: null,
            queueUuid: null,
            lastAction: null,
            syncStatus: null,
            error: null,
            startSyncAt: null,
            syncedAt: null,
        },
        filters: null,
        showMore: true,
        clearChannelProductVariantListOnResponse: false,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        instantClearChannelProductVariantList(state) {
            channelProductVariantAdapter.removeAll(state);
        },
        clearChannelProductVariantList(state) {
            state.clearChannelProductVariantListOnResponse = true;
        },
        updateItem(state, action) {
            state.status ='channelProductVariant/updateItem'
            channelProductVariantAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: {
        [channelProductVariantApi.fetchAll.pending]: (state, action) => {
            state.status = 'channelProductVariant/fetchAll/pending'
        },
        [channelProductVariantApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'channelProductVariant/fetchAll/succeeded'

            if (state.clearChannelProductVariantListOnResponse) {
                channelProductVariantAdapter.setAll(state, action.payload.entities)
            } else {
                channelProductVariantAdapter.addMany(state, action.payload.entities)
            }
            state.clearProductListOnResponse = false;
            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [channelProductVariantApi.fetchAll.rejected]: (state, action) => {
            state.status = 'channelProductVariant/fetchAll/rejected'
            state.error = action.payload
        },
        [channelProductVariantApi.refreshItem.pending]: (state, action) => {
            state.status = 'channelProductVariant/refreshItem/pending'
        },
        [channelProductVariantApi.refreshItem.fulfilled]: (state, action) => {
            state.status = 'channelProductVariant/refreshItem/succeeded'
            channelProductVariantAdapter.upsertOne(state, action.payload)
        },

    }
});

export const {
    selectAll: selectAllChannelProductVariants,
} = channelProductVariantAdapter.getSelectors((state) => state.channelProductVariantReducer)

export const selectChannelProductVariantsByChannelProductId = createSelector(
    (state, _) => selectAllChannelProductVariants(state),
    (_, id) => id,
    (channelProductVariants, id) => {
        let filteredItems=[];
        for (const channelProductVariant of channelProductVariants) {
            if (channelProductVariant.channel_product_id === id) {
                filteredItems.push(channelProductVariant);
            }
        }
        return filteredItems;
    }
)

export const selectChannelProductVariantsByChannelId = createSelector(
    (state, _) => selectAllChannelProductVariants(state),
    (_, id) => id,
    (channelProductVariants, id) => {
        let filteredItems=[];
        for (const channelProductVariant of channelProductVariants) {
            if (channelProductVariant.channel_id === id) {
                filteredItems.push(channelProductVariant);
            }
        }
        return filteredItems;
    }
)
export const selectChannelProductVariantByChannelIdAndVariantId = createSelector(
    (state, _, __) => selectAllChannelProductVariants(state),
    (_, channelId, __) => channelId,
    (_, __, variantId) => variantId,
    (channelProductVariants, channelId, variantId) => {
        let channelProductVariant = null;
        if(channelId && variantId) {
            channelProductVariants.forEach( item => {
                // console.log(label, channelProductVariant)
                if(channelId === item.channel_id) {
                    if(variantId === item.product_variant_id) {
                        channelProductVariant = item;
                    }
                }
            })
        }
        return channelProductVariant;
    }
)

export const { setSorting, setFilters, updateItem, clearChannelProductVariantList, instantClearChannelProductVariantList } = channelProductVariantSlice.actions;

export default channelProductVariantSlice.reducer;
