import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class MiintoSeasonMapApiClient extends AdvancedApiClient {

    static entityType = "MiintoSeasonMap"

    constructor(token, seasonId, id) {
        super();

        this.setEntityType(MiintoSeasonMapApiClient.entityType)

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/season/{sid}/miinto-map/{id}")
                .setRouteParam("sid", seasonId)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/season/{sid}/miinto-map")
                .setRouteParam("sid", seasonId);
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}