import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ChannelSyncApiClient extends AdvancedApiClient {

    static entityType = "ChannelSync"

    constructor(token, channelId) {
        super();

        this.setEntityType(ChannelSyncApiClient.entityType);

        if(channelId !== undefined && channelId !== null) {
            this.setRoute("/api/v1/channel/{id}/sync")
                .setRouteParam("id", channelId);
        } else {
            throw new Error("ERROR: channelId is required")
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}