import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { FarfetchModel } from '../models/farfetch/FarfetchModel';


const farfetchBoxAdapter = createEntityAdapter({})

export const farfetchBoxApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'farfetch/box/fetchAll', 
        async ({channelId, storeLocation, page, term}, thunkAPI) => {
            try {
                const token = farfetchBoxApi._getToken(thunkAPI);
                const farfetchModel = new FarfetchModel(token, channelId);

                /** @type {Pager} */
                const pager = await farfetchModel.getBoxes(storeLocation, page, term);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const farfetchBoxSlice = createSlice({
    name: "farfetch/box",
    initialState: farfetchBoxAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearBoxList(state, action) {
            farfetchBoxAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [farfetchBoxApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'farfetch/box/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                farfetchBoxAdapter.addMany(state, action.payload.entities)
            } else {
                farfetchBoxAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [farfetchBoxApi.fetchAll.rejected]: (state, action) => {
            state.status = 'farfetch/box/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllFarfetchBoxes,
} = farfetchBoxAdapter.getSelectors((state) => state.farfetchBoxReducer)

export const { clearBoxList } = farfetchBoxSlice.actions;

export default farfetchBoxSlice.reducer;
