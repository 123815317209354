import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class WooCommerceBrandMapApiClient extends AdvancedApiClient {

    static entityType = "WooCommerceBrandMap"

    constructor(token, brandId, id) {
        super();

        this.setEntityType(WooCommerceBrandMapApiClient.entityType)

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/brand/{bid}/woocommerce-map/{id}")
                .setRouteParam("bid", brandId)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/brand/{bid}/woocommerce-map")
                .setRouteParam("bid", brandId);
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}