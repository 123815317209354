// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { AttributeApiClient } from './AttributeApiClient';
import { CategoryApiClient } from './CategoryApiClient';
import { TagApiClient } from './TagApiClient';
import { TermApiClient } from './TermApiClient';

export class WooCommerceModel {

    _token = null;
    _channelId = null;

    constructor(token, channelId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._channelId = (channelId !== null && channelId !== undefined) ? channelId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getCategories(page, term, parentId) {
        const catClient = new CategoryApiClient(this._token, this._channelId);
        catClient.setPagingStep(5);
        catClient.orderBy([new OrderCriteria('name')]);

        if(parentId) catClient.filterByLHSFilter([new LHSIntFilter('parent_id', LHSIntFilter.LHSOperator.eq, parentId)])
        if(term != null && term.length > 0) catClient.filterByString(term);

        return await catClient.fetchPage(page);
    }

    async getTags(page, term) {
        const tagClient = new TagApiClient(this._token, this._channelId);
        tagClient.setPagingStep(5);
        tagClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) tagClient.filterByString(term);

        return await tagClient.fetchPage(page);
    }

    async getAttributes(page, term) {
        const attrClient = new AttributeApiClient(this._token, this._channelId);
        attrClient.setPagingStep(5);
        attrClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) attrClient.filterByString(term);

        return await attrClient.fetchPage(page);
    }

    async getTerms(attributeId, page, term) {
        const termClient = new TermApiClient(this._token, this._channelId, attributeId);
        termClient.setPagingStep(5);
        termClient.orderBy([new OrderCriteria('name')]);

        if(term != null && term.length > 0) termClient.filterByString(term);

        return await termClient.fetchPage(page);
    }
}