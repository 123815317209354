import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ChannelLogApiClient extends AdvancedApiClient {

    static entityType = "ChannelLog";

    constructor(token, cid, version) {
        super();

        this.setEntityType(ChannelLogApiClient.entityType);

        this.setRoute("/api/v1/channel/{cid}/log/{version}")
            .setRouteParam("cid", cid)
            .setRouteParam("version", version);

        if (token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token));
        }
    }
}