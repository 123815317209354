import { LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { AvgAnalyticsApiClient } from './AvgAnalyticsApiClient';

export class AvgAnalyticsModel {
    static TYPE_FASHION = "fashion"

    static AVG_TYPE = {
        all: { value: 'all', name: 'all value' },
        noMinMax: { value: 'no_min_max', name: 'no min and max' },
    }

    static TAXONOMY = {
        brand: 'brand',
        category: 'category',
        season: 'season',
    }

    _token = null;
    _productType = null;

    constructor(token, productType) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._productType = productType;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setProductType(productType) {
        this._productType = productType;
    }

    async getAnalytics(page, term, sorting, filters, avgType, taxonomy) {
        const analyticsClient = new AvgAnalyticsApiClient(this._token, avgType, this._productType, taxonomy);
        analyticsClient.setPagingStep(15);

        if (term != null && term.length > 2) analyticsClient.filterByString(term);

        if (taxonomy !== undefined) {
            let orderCriteria = [];
            if (sorting.id !== null && sorting.id !== undefined) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10));
            if (sorting.brandCode !== null && sorting.brandCode !== undefined) orderCriteria.push((new OrderCriteria('brand_code', sorting.brandCode).setWeight(20)));
            if (sorting.brandName !== null && sorting.brandName !== undefined) orderCriteria.push((new OrderCriteria('brand_name', sorting.brandName).setWeight(30)));
            if (sorting.categoryCode !== null && sorting.categoryCode !== undefined) orderCriteria.push((new OrderCriteria('category_code', sorting.categoryCode).setWeight(21)));
            if (sorting.categoryName !== null && sorting.categoryName !== undefined) orderCriteria.push((new OrderCriteria('category_name', sorting.categoryName).setWeight(31)));
            if (sorting.seasonCode !== null && sorting.seasonCode !== undefined) orderCriteria.push((new OrderCriteria('season_code', sorting.seasonCode).setWeight(22)));
            if (sorting.seasonName !== null && sorting.seasonName !== undefined) orderCriteria.push((new OrderCriteria('season_name', sorting.seasonName).setWeight(32)));
            if (orderCriteria.length > 0) analyticsClient.orderBy(orderCriteria);
        }

        let filterCriteria = [];
        if (taxonomy === AvgAnalyticsModel.TAXONOMY.brand && filters.brandCode !== null && filters.brandCode !== undefined)
            filterCriteria.push(new LHSStringFilter('brand_code', LHSStringFilter.LHSOperator.eq, filters.brandCode));
        if (taxonomy === AvgAnalyticsModel.TAXONOMY.category && filters.categoryCode !== null && filters.categoryCode !== undefined)
            filterCriteria.push(new LHSStringFilter('category_code', LHSStringFilter.LHSOperator.eq, filters.categoryCode));
        if (taxonomy === AvgAnalyticsModel.TAXONOMY.season && filters.seasonCode !== null && filters.seasonCode !== undefined)
            filterCriteria.push(new LHSStringFilter('season_code', LHSStringFilter.LHSOperator.eq, filters.seasonCode));
        if (filterCriteria.length > 0) analyticsClient.filterByLHSFilter(filterCriteria);

        if (page !== null) {
            // console.log("fetchPage");
            return await analyticsClient.fetchPage(page);
        } else {
            // console.log("findAll");
            return await analyticsClient.findAll();
        }
    }
}