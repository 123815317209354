import { LHSFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { BrandApiClient } from './BrandApiClient';
import { FarfetchBrandMapApiClient } from './FarfetchBrandMapApiClient';
import { IntramirrorBrandMapApiClient } from './IntramirrorBrandMapApiClient';
import { WooCommerceBrandMapApiClient } from './WooCommerceBrandMapApiClient';
import { MiintoBrandMapApiClient } from './MiintoBrandMapApiClient';

export class BrandModel {
    _token = null;
    _brandId = null;

    constructor(token, brandId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._brandId = (brandId !== null && brandId !== undefined) ? brandId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getBrands(page, term, sorting, filter = {}) {
        const brandClient = new BrandApiClient(this._token);
        brandClient.setPagingStep(20);

        let orderCriteria = []

        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))
        if(orderCriteria.length > 0) brandClient.orderBy(orderCriteria);

        let lhsFilters = []
        if(filter.shop_code) lhsFilters.push( new LHSStringFilter('shop_code', LHSFilter.LHSOperator.eq, filter.shop_code) )
        if(lhsFilters.length > 0) brandClient.filterByLHSFilter(lhsFilters);

        if(term != null && term.length > 0) brandClient.filterByString(term);

        return await brandClient.fetchPage(page);
    }

    async getBrand(id) {
        const brandClient = new BrandApiClient(this._token, id);
        return brandClient.findOne();
    }

    async createBrand(shopCode, name) {
        const brandClient = new BrandApiClient(this._token);
        return brandClient.save({
            shop_code: shopCode,
            name: name
        });
    }

    async updateBrand(id, shopCode, name) {
        const brandClient = new BrandApiClient(this._token, id);
        return brandClient.save({
            id: id,
            shop_code: shopCode,
            name: name
        });
    }

    async deleteBrand(id) {
        const brandClient = new BrandApiClient(this._token, id);
        return brandClient.remove();
    }

    async createMapToFarfetch(channelId, ffBrandId) {
        const ffClient = new FarfetchBrandMapApiClient(this._token, this._brandId);
        return ffClient.save({
            channel_id: channelId,
            ff_brand_id: ffBrandId
        })
    }

    async updateMapToFarfetch(id, channelId, ffBrandId) {
        const ffClient = new FarfetchBrandMapApiClient(this._token, this._brandId, id);
        return ffClient.save({
            id: id,
            channel_id: channelId,
            ff_brand_id: ffBrandId
        })
    }

    async deleteMapToFarfetch(id) {
        const ffClient = new FarfetchBrandMapApiClient(this._token, this._brandId, id);
        ffClient.remove()
    }

    async createMapToWooCommerce(channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceBrandMapApiClient(this._token, this._brandId);
        return wcClient.save({
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async updateMapToWooCommerce(id, channelId, wcAttributeId, wcTermId) {
        const wcClient = new WooCommerceBrandMapApiClient(this._token, this._brandId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_attribute_id: wcAttributeId,
            wc_attribute_term_id: wcTermId
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceBrandMapApiClient(this._token, this._brandId, id);
        wcClient.remove()
    }

    async createMapToIntramirror(channelId, imName) {
        const imClient = new IntramirrorBrandMapApiClient(this._token, this._brandId);
        return imClient.save({
            channel_id: channelId,
            im_name: imName
        })
    }

    async updateMapToIntramirror(id, channelId, imName) {
        const imClient = new IntramirrorBrandMapApiClient(this._token, this._brandId, id);
        return imClient.save({
            id: id,
            channel_id: channelId,
            im_name: imName
        })
    }

    async deleteMapToIntramirror(id) {
        const imClient = new IntramirrorBrandMapApiClient(this._token, this._brandId, id);
        imClient.remove()
    }

    async createMapToMiinto(channelId, mntName) {
        const mntClient = new MiintoBrandMapApiClient(this._token, this._brandId);
        return mntClient.save({
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async updateMapToMiinto(id, channelId, mntName) {
        const mntClient = new MiintoBrandMapApiClient(this._token, this._brandId, id);
        return mntClient.save({
            id: id,
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async deleteMapToMiinto(id) {
        const mntClient = new MiintoBrandMapApiClient(this._token, this._brandId, id);
        mntClient.remove()
    }
}