import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { SeasonModel } from '../models/season/SeasonModel';


const seasonAdapter = createEntityAdapter()

export const seasonApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'season/fetchAll', 
        async ({page, term, sorting, filter}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token);

                /** @type {Pager} */
                const pager = await seasonModel.getSeasons(page, term, sorting, filter);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'season/fetchById', 
        async ({id}, thunkAPI) => {
            const token = seasonApi._getToken(thunkAPI);
            const seasonModel = new SeasonModel(token, id);

            try {
                return await seasonModel.getSeason(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'season/create', 
        async ({shopCode, name, enabled}, thunkAPI) => {
            const token = seasonApi._getToken(thunkAPI);
            const seasonModel = new SeasonModel(token);

            try {
                return await seasonModel.createSeason(shopCode, name, enabled);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'season/update', 
        async ({id, shopCode, name, enabled}, thunkAPI) => {
            const token = seasonApi._getToken(thunkAPI);
            const seasonModel = new SeasonModel(token);

            try {
                return await seasonModel.updateSeason(id, shopCode, name, enabled);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'season/delete', 
        async (season, thunkAPI) => {
            const token = seasonApi._getToken(thunkAPI);
            const seasonModel = new SeasonModel(token, season.id);

            try {
                await seasonModel.deleteSeason(season.id);
                return season;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createFarfetchMap: createAsyncThunk(
        'season/map/farfetch/create', 
        async ({season, channelId, ffSeasonId}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const ffSeasonMap = await seasonModel.createMapToFarfetch(channelId, ffSeasonId);
                let farfetch_map = season.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffSeasonMap)

                return { ...season, farfetch_map: farfetch_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateFarfetchMap: createAsyncThunk(
        'season/map/farfetch/update', 
        async ({season, mapId, channelId, ffSeasonId}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const ffSeasonMap =  await seasonModel.updateMapToFarfetch(mapId, channelId, ffSeasonId);
                return {
                    ...season, 
                    farfetch_map: season.farfetch_map.map(function(item) { return item.id == mapId ? ffSeasonMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'season/map/woocommerce/create', 
        async ({season, channelId, wcTagId}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const wcSeasonMap = await seasonModel.createMapToWooCommerce(channelId, wcTagId);
                let woocommerce_map = season.woocommerce_map.map(a => {return {...a}})
                woocommerce_map.push(wcSeasonMap)

                return { ...season, woocommerce_map: woocommerce_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'season/map/woocommerce/update', 
        async ({season, mapId, channelId, wcTagId}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const wcSeasonMap =  await seasonModel.updateMapToWooCommerce(mapId, channelId, wcTagId);
                return {
                    ...season,
                    woocommerce_map: season.woocommerce_map.map((item) => { return item.id == mapId ? wcSeasonMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createIntramirrorMap: createAsyncThunk(
        'season/map/intramirror/create', 
        async ({season, channelId, imName}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const imSeasonMap = await seasonModel.createMapToIntramirror(channelId, imName);
                let intramirror_map = season.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imSeasonMap)

                return { ...season, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateIntramirrorMap: createAsyncThunk(
        'season/map/intramirror/update', 
        async ({season, mapId, channelId, imName}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const imSeasonMap =  await seasonModel.updateMapToIntramirror(mapId, channelId, imName);
                let intramirror_map = season.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imSeasonMap)

                return { ...season, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createMiintoMap: createAsyncThunk(
        'season/map/miinto/create', 
        async ({season, channelId, mntName}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const mntSeasonMap = await seasonModel.createMapToMiinto(channelId, mntName);
                let miinto_map = season.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntSeasonMap)

                return { ...season, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateMiintoMap: createAsyncThunk(
        'season/map/miinto/update', 
        async ({season, mapId, channelId, mntName}, thunkAPI) => {
            try {
                const token = seasonApi._getToken(thunkAPI);
                const seasonModel = new SeasonModel(token, season.id);

                const mntSeasonMap =  await seasonModel.updateMapToMiinto(mapId, channelId, mntName);
                let miinto_map = season.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntSeasonMap)

                return { ...season, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const seasonSlice = createSlice({
    name: "season",
    initialState: seasonAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedSeason: null,
        lastEditSeason: null,
        lastDeleteSeason: null,
    }),
    reducers: {
        setLastEditSeason(state, action) {
            state.lastEditSeason = action.payload
        },
        setLastDeleteSeason(state, action) {
            state.lastDeleteSeason = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedSeason = null;
        },
        clearSeasonList(state, action) {
            seasonAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [seasonApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'season/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                seasonAdapter.addMany(state, action.payload.entities)
            } else {
                seasonAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [seasonApi.fetchAll.rejected]: (state, action) => {
            state.status = 'season/fetchAll/rejected'
            state.error = action.payload
        },
        [seasonApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'season/fetchById/succeeded'
            seasonAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [seasonApi.fetchById.rejected]: (state, action) => {
            state.status = 'season/fetchById/rejected'
            state.error = action.payload
        },
        [seasonApi.create.fulfilled]: (state, action) => {
            state.status = 'season/create/succeeded'
            state.error = null;
            seasonAdapter.addOne(state, action.payload);
            state.lastAddedSeason = action.payload;
        },
        [seasonApi.create.pending]: (state, action) => {
            state.status = 'season/create/pending';
        },
        [seasonApi.create.rejected]: (state, action) => {
            state.status = 'season/create/rejected';
            state.error = action.payload;
        },
        [seasonApi.delete.fulfilled]: (state, action) => {
            state.status = 'season/delete/succeeded'
            state.error = null;
            seasonAdapter.removeOne(state, action.payload.id)
        },
        [seasonApi.delete.rejected]: (state, action) => {
            state.status = 'season/delete/rejected';
            state.error = action.payload;
        },
        [seasonApi.update.fulfilled]: (state, action) => {
            state.status = 'season/update/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.update.pending]: (state, action) => {
            state.status = 'season/update/pending'
        },
        [seasonApi.update.rejected]: (state, action) => {
            state.status = 'season/update/rejected';
            state.error = action.payload;
        },
        [seasonApi.createFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'season/map/farfetch/create/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.createFarfetchMap.rejected]: (state, action) => {
            state.status = 'season/map/farfetch/create/rejected';
            state.error = action.payload;
        },
        [seasonApi.updateFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'season/map/farfetch/update/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.updateFarfetchMap.rejected]: (state, action) => {
            state.status = 'season/map/farfetch/update/rejected';
            state.error = action.payload;
        },
        [seasonApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'season/map/woocommerce/create/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'season/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [seasonApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'season/map/woocommerce/update/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'season/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
        [seasonApi.createIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'season/map/intramirror/create/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.createIntramirrorMap.rejected]: (state, action) => {
            state.status = 'season/map/intramirror/create/rejected';
            state.error = action.payload;
        },
        [seasonApi.updateIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'season/map/intramirror/update/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.updateIntramirrorMap.rejected]: (state, action) => {
            state.status = 'season/map/intramirror/update/rejected';
            state.error = action.payload;
        },
        [seasonApi.createMiintoMap.fulfilled]: (state, action) => {
            state.status = 'season/map/miinto/create/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.createMiintoMap.rejected]: (state, action) => {
            state.status = 'season/map/miinto/create/rejected';
            state.error = action.payload;
        },
        [seasonApi.updateMiintoMap.fulfilled]: (state, action) => {
            state.status = 'season/map/miinto/update/succeeded'
            state.error = null;
            seasonAdapter.upsertOne(state, action.payload);
        },
        [seasonApi.updateMiintoMap.rejected]: (state, action) => {
            state.status = 'season/map/miinto/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedSeasons = createSelector(
    (state) => selectAllSeasons(state),
    (state) => state.seasonReducer.lastAddedSeason,
    (seasons, lastSeason) => {
        return seasons.sort((a, b)=>{ 
            return (lastSeason && a.id === lastSeason.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
);

export const selectSeasonByShopCode = createSelector(
    (state, _) => selectAllSeasons(state),
    (_, code) => code,
    (items, code) => {
        return items.find( (item) => { 
            return item.shop_code === code 
        });
    }
);

export const {
    selectById: selectSeasonById,
    selectAll: selectAllSeasons,
} = seasonAdapter.getSelectors((state) => state.seasonReducer)

export const { setLastEditSeason, setLastDeleteSeason, setTerm, setSorting, clearSeasonList } = seasonSlice.actions;

export default seasonSlice.reducer;
