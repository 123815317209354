import { LHSFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { SeasonApiClient } from './SeasonApiClient';
import { FarfetchSeasonMapApiClient } from './FarfetchSeasonMapApiClient';
import { WooCommerceSeasonMapApiClient } from './WooCommerceSeasonMapApiClient';
import { IntramirrorSeasonMapApiClient } from './IntramirrorSeasonMapApiClient';
import { MiintoSeasonMapApiClient } from './MiintoSeasonMapApiClient';

export class SeasonModel {
    _token = null;
    _seasonId = null;

    constructor(token, seasonId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._seasonId = (seasonId !== null && seasonId !== undefined) ? seasonId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getSeasons(page, term, sorting, filter = {}) {
        const seasonClient = new SeasonApiClient(this._token);
        seasonClient.setPagingStep(20);

        let orderCriteria = []
        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))
        if(orderCriteria.length > 0) seasonClient.orderBy(orderCriteria);

        let lhsFilters = []
        if(filter.shop_code) lhsFilters.push( new LHSStringFilter('shop_code', LHSFilter.LHSOperator.eq, filter.shop_code) )
        if(lhsFilters.length > 0) seasonClient.filterByLHSFilter(lhsFilters);

        if(term != null && term.length > 2) seasonClient.filterByString(term);

        return await seasonClient.fetchPage(page);
    }

    async getSeason(id) {
        const seasonClient = new SeasonApiClient(this._token, id);
        return seasonClient.findOne();
    }

    async createSeason(shopCode, name, enabled) {
        const seasonClient = new SeasonApiClient(this._token);
        return seasonClient.save({
            shop_code: shopCode,
            name: name, 
            enabled: enabled
        });
    }

    async updateSeason(id, shopCode, name, enabled) {
        const seasonClient = new SeasonApiClient(this._token, id);
        return seasonClient.save({
            id: id,
            shop_code: shopCode,
            name: name, 
            enabled: enabled
        });
    }

    async deleteSeason(id) {
        const seasonClient = new SeasonApiClient(this._token, id);
        return seasonClient.remove();
    }

    async createMapToFarfetch(channelId, ffSeasonId) {
        const ffClient = new FarfetchSeasonMapApiClient(this._token, this._seasonId);
        return ffClient.save({
            channel_id: channelId,
            ff_season_id: ffSeasonId
        })
    }

    async updateMapToFarfetch(id, channelId, ffSeasonId) {
        const ffClient = new FarfetchSeasonMapApiClient(this._token, this._seasonId, id);
        return ffClient.save({
            id: id,
            channel_id: channelId,
            ff_season_id: ffSeasonId
        })
    }

    async deleteMapToFarfetch(id) {
        const ffClient = new FarfetchSeasonMapApiClient(this._token, this._seasonId, id);
        ffClient.remove()
    }

    async createMapToWooCommerce(channelId, wcTagId) {
        const wcClient = new WooCommerceSeasonMapApiClient(this._token, this._seasonId);
        return wcClient.save({
            channel_id: channelId,
            wc_tag_id: wcTagId,
        })
    }

    async updateMapToWooCommerce(id, channelId, wcTagId) {
        const wcClient = new WooCommerceSeasonMapApiClient(this._token, this._seasonId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_tag_id: wcTagId,
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceSeasonMapApiClient(this._token, this._seasonId, id);
        wcClient.remove()
    }

    async createMapToIntramirror(channelId, imName) {
        const imClient = new IntramirrorSeasonMapApiClient(this._token, this._seasonId);
        return imClient.save({
            channel_id: channelId,
            im_name: imName
        })
    }

    async updateMapToIntramirror(id, channelId, imName) {
        const imClient = new IntramirrorSeasonMapApiClient(this._token, this._seasonId, id);
        return imClient.save({
            id: id,
            channel_id: channelId,
            im_name: imName
        })
    }

    async deleteMapToIntramirror(id) {
        const imClient = new IntramirrorSeasonMapApiClient(this._token, this._seasonId, id);
        imClient.remove()
    }

    async createMapToMiinto(channelId, mntName) {
        const mntClient = new MiintoSeasonMapApiClient(this._token, this._seasonId);
        return mntClient.save({
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async updateMapToMiinto(id, channelId, mntName) {
        const mntClient = new MiintoSeasonMapApiClient(this._token, this._seasonId, id);
        return mntClient.save({
            id: id,
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async deleteMapToMiinto(id) {
        const mntClient = new MiintoSeasonMapApiClient(this._token, this._seasonId, id);
        mntClient.remove()
    }
}