import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { AvgAnalyticsModel } from '../models/AvgAnalyticsModel';

const avgAnalyticsBrandAdapter = createEntityAdapter();

export const avgAnalyticsBrandApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'avgAnalyticsBrand/fetchAll',
        async ({ page, term, sorting, filters, avgType }, thunkAPI) => {
            try {
                const token = avgAnalyticsBrandApi._getToken(thunkAPI);
                const avgAnalyticsModel = new AvgAnalyticsModel(token, AvgAnalyticsModel.TYPE_FASHION);

                /** @type {Pager} */
                const pager = await avgAnalyticsModel.getAnalytics(
                    page, term, sorting, filters, avgType, AvgAnalyticsModel.TAXONOMY.brand);

                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }

            // try {
            //     const token = avgAnalyticsBrandApi._getToken(thunkAPI);
            //     const avgAnalyticsModel = new AvgAnalyticsModel(token, AvgAnalyticsModel.TYPE_FASHION);

            //     if (page !== null) {
            //         /** @type {Pager} */
            //         const pager = await avgAnalyticsModel.getAnalytics(page, term, sorting, filters, avgType);

            //         return {
            //             hasPrevPage: pager.hasPrevPage,
            //             hasNextPage: pager.hasNextPage,
            //             entities: pager.getEntities()
            //         }
            //     } else {
            //         return {
            //             hasPrevPage: null,
            //             hasNextPage: null,
            //             entities: await avgAnalyticsModel.getAnalytics(page, term, sorting, filters, avgType),
            //         }
            //     }
            // } catch (apiError) {
            //     return thunkAPI.rejectWithValue(apiError.toJson());
            // }
        }
    ),
}

const avgAnalyticsBrandSlice = createSlice({
    name: "avgAnalyticsBrand",
    initialState: avgAnalyticsBrandAdapter.getInitialState({
        status: 'idle',
        error: null,
        avgType: null,
        term: null,
        sorting: null,
        filters: {
            brandCode: null,
        },
        showMore: true,
        clearListOnResponse: false,
    }),
    reducers: {
        setAvgType(state, action) {
            state.avgType = action.payload
        },
        setSorting(state, action) {
            state.sorting = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        clearAvgAnalytics(state) {
            state.clearListOnResponse = true
        },
    },
    extraReducers: {
        [avgAnalyticsBrandApi.fetchAll.pending]: (state, action) => {
            state.status = 'avgAnalyticsBrand/fetchAll/pending';
        },
        [avgAnalyticsBrandApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'avgAnalyticsBrand/fetchAll/succeeded';
            if(state.clearListOnResponse) {
                avgAnalyticsBrandAdapter.setAll(state, action.payload.entities)
            } else {
                avgAnalyticsBrandAdapter.addMany(state, action.payload.entities)
            }
            state.clearListOnResponse = false
            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [avgAnalyticsBrandApi.fetchAll.rejected]: (state, action) => {
            state.status = 'avgAnalyticsBrand/fetchAll/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectById: selectAnalyticsById,
    selectAll: selectAllAnalytics,
} = avgAnalyticsBrandAdapter.getSelectors((state) => state.avgAnalyticsBrandReducer)

export const { setAvgType, setSorting, setFilters, clearAvgAnalytics } = avgAnalyticsBrandSlice.actions;

export default avgAnalyticsBrandSlice.reducer;