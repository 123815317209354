import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { IntramirrorModel } from '../models/intramirror/IntramirrorModel';


const intramirrorCategoryAdapter = createEntityAdapter({})

export const intramirrorCategoryApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'intramirror/category/fetchAll', 
        async ({channelId, page, term, filter}, thunkAPI) => {
            try {
                const token = intramirrorCategoryApi._getToken(thunkAPI);
                const intramirrorModel = new IntramirrorModel(token, channelId);

                return await intramirrorModel.getCategories(page, term, filter);
                // /** @type {Pager} */
                // const pager = await intramirrorModel.getCategories(page, term, filter);
                
                // return {
                //     hasPrevPage: pager.hasPrevPage,
                //     hasNextPage: pager.hasNextPage,
                //     entities: pager.getEntities()
                // }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const intramirrorCategorySlice = createSlice({
    name: "intramirror/category",
    initialState: intramirrorCategoryAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearCategoryList(state, action) {
            intramirrorCategoryAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [intramirrorCategoryApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'intramirror/category/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                intramirrorCategoryAdapter.addMany(state, action.payload)
            } else {
                intramirrorCategoryAdapter.setAll(state, action.payload)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [intramirrorCategoryApi.fetchAll.rejected]: (state, action) => {
            state.status = 'intramirror/category/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const selectIntramirrorCategories = createSelector(
    (state, _) => selectAllIntramirrorCategories(state),
    (_, filter) => filter,
    (items, filter) => {
        return (filter !== undefined && filter !== null && filter !== null) 
            ? items.filter((item) => {
                return item.name.startsWith(filter);
              })
            : items
    }
  );

export const {
    selectAll: selectAllIntramirrorCategories,
} = intramirrorCategoryAdapter.getSelectors((state) => state.intramirrorCategoryReducer)

export const { clearCategoryList } = intramirrorCategorySlice.actions;

export default intramirrorCategorySlice.reducer;
