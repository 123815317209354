import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { SizeModel } from '../models/size/SizeModel';


const sizeAdapter = createEntityAdapter()

export const sizeApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'size/fetchAll', 
        async ({page, term, sorting}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token);

                /** @type {Pager} */
                const pager = await sizeModel.getSizes(page, term, sorting);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'size/fetchById', 
        async ({id}, thunkAPI) => {
            const token = sizeApi._getToken(thunkAPI);
            const sizeModel = new SizeModel(token, id);

            try {
                return await sizeModel.getSize(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'size/create', 
        async ({shopCode, name}, thunkAPI) => {
            const token = sizeApi._getToken(thunkAPI);
            const sizeModel = new SizeModel(token);

            try {
                return await sizeModel.createSize(shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'size/update', 
        async ({id, shopCode, name}, thunkAPI) => {
            const token = sizeApi._getToken(thunkAPI);
            const sizeModel = new SizeModel(token);

            try {
                return await sizeModel.updateSize(id, shopCode, name);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'size/delete', 
        async (size, thunkAPI) => {
            const token = sizeApi._getToken(thunkAPI);
            const sizeModel = new SizeModel(token, size.id);

            try {
                await sizeModel.deleteSize(size.id);
                return size;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createFarfetchMap: createAsyncThunk(
        'size/map/farfetch/create', 
        async ({size, channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const ffSizeMap = await sizeModel.createMapToFarfetch(channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName);
                let farfetch_map = size.farfetch_map.map(a => {return {...a}})
                farfetch_map.push(ffSizeMap)

                return { ...size, farfetch_map: farfetch_map }
            } catch (apiError) {
                console.log(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateFarfetchMap: createAsyncThunk(
        'size/map/farfetch/update', 
        async ({size, mapId, channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const ffSizeMap =  await sizeModel.updateMapToFarfetch(mapId, channelId, ffGenderId, ffCategoryId, ffSizeRangeId, ffSizeRangeName, ffSizeId, ffSizeName);
                return {
                    ...size, 
                    farfetch_map: size.farfetch_map.map(function(item) { return item.id == mapId ? ffSizeMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createWooCommerceMap: createAsyncThunk(
        'size/map/woocommerce/create', 
        async ({size, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const wcSizeMap = await sizeModel.createMapToWooCommerce(channelId, wcAttributeId, wcTermId);
                return {
                    ...size, 
                    woocommerce_map: size.woocommerce_map.push(wcSizeMap)
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateWooCommerceMap: createAsyncThunk(
        'size/map/woocommerce/update', 
        async ({size, mapId, channelId, wcAttributeId, wcTermId}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const wcSizeMap =  await sizeModel.updateMapToWooCommerce(mapId, channelId, wcAttributeId, wcTermId);
                return {
                    ...size,
                    woocommerce_map: size.woocommerce_map.map((item) => { return item.id == mapId ? wcSizeMap : item; })
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),

    createIntramirrorMap: createAsyncThunk(
        'size/map/intramirror/create', 
        async ({size, channelId, imName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const imSizeMap = await sizeModel.createMapToIntramirror(channelId, imName);
                let intramirror_map = size.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imSizeMap)

                return { ...size, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateIntramirrorMap: createAsyncThunk(
        'size/map/intramirror/update', 
        async ({size, mapId, channelId, imName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const imSizeMap =  await sizeModel.updateMapToIntramirror(mapId, channelId, imName);
                let intramirror_map = size.intramirror_map.map(a => {return {...a}})
                intramirror_map.push(imSizeMap)

                return { ...size, intramirror_map: intramirror_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    createMiintoMap: createAsyncThunk(
        'size/map/miinto/create', 
        async ({size, channelId, brandId, mntName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const mntSizeMap = await sizeModel.createMapToMiinto(channelId, brandId, mntName);
                let miinto_map = size.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntSizeMap)

                return { ...size, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updateMiintoMap: createAsyncThunk(
        'size/map/miinto/update', 
        async ({size, mapId, channelId, brandId, mntName}, thunkAPI) => {
            try {
                const token = sizeApi._getToken(thunkAPI);
                const sizeModel = new SizeModel(token, size.id);

                const mntSizeMap =  await sizeModel.updateMapToMiinto(mapId, channelId, brandId, mntName);
                let miinto_map = size.miinto_map.map(a => {return {...a}})
                miinto_map.push(mntSizeMap)

                return { ...size, miinto_map: miinto_map }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const sizeSlice = createSlice({
    name: "size",
    initialState: sizeAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            shop_code: null,
            name: null,
        },
        showMore: true,
        lastAddedSize: null,
        lastEditSize: null,
        lastDeleteSize: null,
    }),
    reducers: {
        setLastEditSize(state, action) {
            state.lastEditSize = action.payload
        },
        setLastDeleteSize(state, action) {
            state.lastDeleteSize = action.payload
        },
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
          state.lastAddedSize = null;
        },
    },
    extraReducers: {
        [sizeApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'size/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                sizeAdapter.addMany(state, action.payload.entities)
            } else {
                sizeAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [sizeApi.fetchAll.rejected]: (state, action) => {
            state.status = 'size/fetchAll/rejected'
            state.error = action.payload
        },
        [sizeApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'size/fetchById/succeeded'
            sizeAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [sizeApi.fetchById.rejected]: (state, action) => {
            state.status = 'size/fetchById/rejected'
            state.error = action.payload
        },
        [sizeApi.create.fulfilled]: (state, action) => {
            state.status = 'size/create/succeeded'
            state.error = null;
            sizeAdapter.addOne(state, action.payload);
            state.lastAddedSize = action.payload;
        },
        [sizeApi.create.pending]: (state, action) => {
            state.status = 'size/create/pending';
        },
        [sizeApi.create.rejected]: (state, action) => {
            state.status = 'size/create/rejected';
            state.error = action.payload;
        },
        [sizeApi.delete.fulfilled]: (state, action) => {
            state.status = 'size/delete/succeeded'
            state.error = null;
            sizeAdapter.removeOne(state, action.payload.id)
        },
        [sizeApi.delete.rejected]: (state, action) => {
            state.status = 'size/delete/rejected';
            state.error = action.payload;
        },
        [sizeApi.update.fulfilled]: (state, action) => {
            state.status = 'size/update/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.update.pending]: (state, action) => {
            state.status = 'size/update/pending'
        },
        [sizeApi.update.rejected]: (state, action) => {
            state.status = 'size/update/rejected';
            state.error = action.payload;
        },
        [sizeApi.createFarfetchMap.pending]: (state, action) => {
            state.status = 'size/map/farfetch/create/pending'
        },
        [sizeApi.createFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'size/map/farfetch/create/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.createFarfetchMap.rejected]: (state, action) => {
            state.status = 'size/map/farfetch/create/rejected';
            state.error = action.payload;
        },
        [sizeApi.updateFarfetchMap.pending]: (state, action) => {
            state.status = 'size/map/farfetch/update/pending'
        },
        [sizeApi.updateFarfetchMap.fulfilled]: (state, action) => {
            state.status = 'size/map/farfetch/update/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.updateFarfetchMap.rejected]: (state, action) => {
            state.status = 'size/map/farfetch/update/rejected';
            state.error = action.payload;
        },
        [sizeApi.createWooCommerceMap.pending]: (state, action) => {
            state.status = 'size/map/woocommerce/create/pending'
        },
        [sizeApi.createWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'size/map/woocommerce/create/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.createWooCommerceMap.rejected]: (state, action) => {
            state.status = 'size/map/woocommerce/create/rejected';
            state.error = action.payload;
        },
        [sizeApi.updateWooCommerceMap.pending]: (state, action) => {
            state.status = 'size/map/woocommerce/update/pending'
        },
        [sizeApi.updateWooCommerceMap.fulfilled]: (state, action) => {
            state.status = 'size/map/woocommerce/update/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.updateWooCommerceMap.rejected]: (state, action) => {
            state.status = 'size/map/woocommerce/update/rejected';
            state.error = action.payload;
        },
        [sizeApi.createIntramirrorMap.pending]: (state, action) => {
            state.status = 'size/map/intramirror/create/pending'
        },
        [sizeApi.createIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'size/map/intramirror/create/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.createIntramirrorMap.rejected]: (state, action) => {
            state.status = 'size/map/intramirror/create/rejected';
            state.error = action.payload;
        },
        [sizeApi.updateIntramirrorMap.pending]: (state, action) => {
            state.status = 'size/map/intramirror/update/pending'
        },
        [sizeApi.updateIntramirrorMap.fulfilled]: (state, action) => {
            state.status = 'size/map/intramirror/update/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.updateIntramirrorMap.rejected]: (state, action) => {
            state.status = 'size/map/intramirror/update/rejected';
            state.error = action.payload;
        },
        [sizeApi.createMiintoMap.fulfilled]: (state, action) => {
            state.status = 'size/map/miinto/create/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.createMiintoMap.rejected]: (state, action) => {
            state.status = 'size/map/miinto/create/rejected';
            state.error = action.payload;
        },
        [sizeApi.updateMiintoMap.fulfilled]: (state, action) => {
            state.status = 'size/map/miinto/update/succeeded'
            state.error = null;
            sizeAdapter.upsertOne(state, action.payload);
        },
        [sizeApi.updateMiintoMap.rejected]: (state, action) => {
            state.status = 'size/map/miinto/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSortedSizes = createSelector(
    (state) => selectAllSizes(state),
    (state) => state.sizeReducer.lastAddedSize,
    (sizes, lastSize) => {
        return sizes.sort((a, b)=>{ 
            return (lastSize && a.id === lastSize.id) ? -1 : (b.id === 0 ? 1 : 0) 
        });
    }
  );

export const {
    selectById: selectSizeById,
    selectAll: selectAllSizes,
} = sizeAdapter.getSelectors((state) => state.sizeReducer)

export const { setLastEditSize, setLastDeleteSize, setTerm, setSorting } = sizeSlice.actions;

export default sizeSlice.reducer;
