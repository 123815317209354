// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { MiintoCategoryApiClient } from './MiintoCategoryApiClient';

export class MiintoModel {

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getCategories(page, term) {
        const miintoClient = new MiintoCategoryApiClient(this._token);
        miintoClient.orderBy([new OrderCriteria('name')]);
        
        if(term != null && term.length > 0) miintoClient.filterByString(term);
        
        if(page) {
            miintoClient.setPagingStep(5);
            return await miintoClient.fetchPage(page);
        } else {
            return await miintoClient.findAll()
        }
    }
}