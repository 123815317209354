import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ChannelProductApiClient extends AdvancedApiClient {

    static entityType = "ChannelProduct"

    constructor(token, id) {
        super();

        this.setEntityType(ChannelProductApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/channel-product/{id}")
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/channel-product")
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}