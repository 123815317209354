import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ProductMediaApiClient extends AdvancedApiClient {

    static entityType = "Media"

    constructor(token, pid, id) {
        super();

        this.setEntityType(ProductMediaApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/product/{pid}/media/{id}")
                .setRouteParam("pid", pid)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/product/{pid}/media")
                .setRouteParam("pid", pid)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}