import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ProductVariantApiClient extends AdvancedApiClient {

    static entityType = "ProductVariant"

    constructor(token, type, pid, id) {
        super();

        this.setEntityType(ProductVariantApiClient.entityType);

        if(id !== undefined && id !== null) {
                this.setRoute("/api/v1/product/{type}/{pid}/variant/{id}")
                    .setRouteParam("type", type)
                    .setRouteParam("pid", pid)
                    .setRouteParam("id", id);
            } else {
                this.setRoute("/api/v1/product/{type}/{pid}/variant")
                    .setRouteParam("type", type)
                    .setRouteParam("pid", pid);
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}