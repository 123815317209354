import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { FarfetchModel } from '../models/farfetch/FarfetchModel';


const farfetchSizeRangeAdapter = createEntityAdapter({})

export const farfetchSizeRangeApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'farfetch/size-range/fetchAll', 
        async ({channelId, brandId, categoryId, page, term}, thunkAPI) => {
            try {
                const token = farfetchSizeRangeApi._getToken(thunkAPI);
                const farfetchModel = new FarfetchModel(token, channelId);

                const sizeranges = await farfetchModel.getSizeRanges(brandId, categoryId, page, term);
                
                return {
                    hasPrevPage: false,
                    hasNextPage: false,
                    entities: sizeranges,
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const farfetchSizeRangeSlice = createSlice({
    name: "farfetch/size-range",
    initialState: farfetchSizeRangeAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearSizeRangeList(state, action) {
            farfetchSizeRangeAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [farfetchSizeRangeApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'farfetch/size-range/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                farfetchSizeRangeAdapter.addMany(state, action.payload.entities)
            } else {
                farfetchSizeRangeAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [farfetchSizeRangeApi.fetchAll.rejected]: (state, action) => {
            state.status = 'farfetch/size-range/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllFarfetchSizeRanges,
} = farfetchSizeRangeAdapter.getSelectors((state) => state.farfetchSizeRangeReducer)

export const { clearSizeRangeList } = farfetchSizeRangeSlice.actions;

export default farfetchSizeRangeSlice.reducer;
