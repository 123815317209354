import {config as configProduction } from "./config.production";
import {config as configDevelopment } from "./config.development";
import {config as configTest } from "./config.test";

const getEnvConfig = () => {
    // console.log("NODE_ENV", process.env.NODE_ENV);

    switch(process.env.NODE_ENV) {
        case 'production' : return configProduction;
        case 'development': return configDevelopment;
        case 'test': return configTest;
        default:
            throw Error("Invalid NODE_ENV param");
    }
}

export const config = getEnvConfig();
