import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class GenderApiClient extends AdvancedApiClient {

    static entityType = "Gender"

    constructor(token, id) {
        super();

        this.setEntityType(GenderApiClient.entityType)

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/gender/{id}")
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/gender");
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}