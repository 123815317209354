import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { FarfetchModel } from '../models/farfetch/FarfetchModel';


const farfetchSeasonAdapter = createEntityAdapter({})

export const farfetchSeasonApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'farfetch/season/fetchAll', 
        async ({channelId, page, term}, thunkAPI) => {
            try {
                const token = farfetchSeasonApi._getToken(thunkAPI);
                const farfetchModel = new FarfetchModel(token, channelId);

                /** @type {Pager} */
                const pager = await farfetchModel.getSeasons(page, term);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const farfetchSeasonSlice = createSlice({
    name: "farfetch/season",
    initialState: farfetchSeasonAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearSeasonList(state, action) {
            farfetchSeasonAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [farfetchSeasonApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'farfetch/season/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                farfetchSeasonAdapter.addMany(state, action.payload.entities)
            } else {
                farfetchSeasonAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [farfetchSeasonApi.fetchAll.rejected]: (state, action) => {
            state.status = 'farfetch/season/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const selectFarfetchSeasons = createSelector(
    (state, _) => selectAllFarfetchSeasons(state),
    (_, filter) => filter,
    (items, filter) => {
        return (filter !== undefined && filter !== null && filter !== null) 
            ? items.filter((item) => {
                return item.name.startsWith(filter);
              })
            : items
    }
  );

export const {
    selectAll: selectAllFarfetchSeasons,
} = farfetchSeasonAdapter.getSelectors((state) => state.farfetchSeasonReducer)

export const { clearSeasonList } = farfetchSeasonSlice.actions;

export default farfetchSeasonSlice.reducer;
