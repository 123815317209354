import { LHSFilter, LHSStringFilter, OrderCriteria } from '../../../../lib/js-apiclient';
import { GenderApiClient } from './GenderApiClient';
import { FarfetchGenderMapApiClient } from './FarfetchGenderMapApiClient';
import { WooCommerceGenderMapApiClient } from './WooCommerceGenderMapApiClient';
import { IntramirrorGenderMapApiClient } from './IntramirrorGenderMapApiClient';
import { MiintoGenderMapApiClient } from './MiintoGenderMapApiClient';

export class GenderModel {
    _token = null;
    _genderId = null;

    constructor(token, genderId) {
        this._token = (token !== null && token !== undefined) ? token : null;
        this._genderId = (genderId !== null && genderId !== undefined) ? genderId : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getGenders(page, term, sorting, filter = {}) {
        const genderClient = new GenderApiClient(this._token);
        genderClient.setPagingStep(20);

        let orderCriteria = []

        if(sorting.shop_code !== null) orderCriteria.push( (new OrderCriteria('shop_code', sorting.shop_code)).setWeight(20) )
        if(sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.name).setWeight(30)))
        if(orderCriteria.length > 0) genderClient.orderBy(orderCriteria);

        let lhsFilters = []
        if(filter.shop_code) lhsFilters.push( new LHSStringFilter('shop_code', LHSFilter.LHSOperator.eq, filter.shop_code) )
        if(lhsFilters.length > 0) genderClient.filterByLHSFilter(lhsFilters);

        if(term != null && term.length > 0) genderClient.filterByString(term);

        return await genderClient.fetchPage(page);
    }

    async getGender(id) {
        const genderClient = new GenderApiClient(this._token, id);
        return genderClient.findOne();
    }

    async createGender(shopCode, name) {
        const genderClient = new GenderApiClient(this._token);
        return genderClient.save({
            shop_code: shopCode,
            name: name
        });
    }

    async updateGender(id, shopCode, name) {
        const genderClient = new GenderApiClient(this._token, id);
        return genderClient.save({
            id: id,
            shop_code: shopCode,
            name: name
        });
    }

    async deleteGender(id) {
        const genderClient = new GenderApiClient(this._token, id);
        return genderClient.remove();
    }

    async createMapToFarfetch(channelId, ffGenderId) {
        const ffClient = new FarfetchGenderMapApiClient(this._token, this._genderId);
        return ffClient.save({
            channel_id: channelId,
            ff_gender_id: ffGenderId
        })
    }

    async updateMapToFarfetch(id, channelId, ffGenderId) {
        const ffClient = new FarfetchGenderMapApiClient(this._token, this._genderId, id);
        return ffClient.save({
            id: id,
            channel_id: channelId,
            ff_gender_id: ffGenderId
        })
    }

    async deleteMapToFarfetch(id) {
        const ffClient = new FarfetchGenderMapApiClient(this._token, this._genderId, id);
        ffClient.remove()
    }

    async createMapToWooCommerce(channelId, wcTagId) {
        const wcClient = new WooCommerceGenderMapApiClient(this._token, this._genderId);
        return wcClient.save({
            channel_id: channelId,
            wc_tag_id: wcTagId,
        })
    }

    async updateMapToWooCommerce(id, channelId, wcTagId) {
        const wcClient = new WooCommerceGenderMapApiClient(this._token, this._genderId, id);
        return wcClient.save({
            id: id,
            channel_id: channelId,
            wc_tag_id: wcTagId,
        })
    }

    async deleteMapToWooCommerce(id) {
        const wcClient = new WooCommerceGenderMapApiClient(this._token, this._genderId, id);
        wcClient.remove()
    }

    async createMapToIntramirror(channelId, imGenderCategoryId) {
        const imClient = new IntramirrorGenderMapApiClient(this._token, this._genderId);
        return imClient.save({
            channel_id: channelId,
            im_gender_category_id: imGenderCategoryId,
        })
    }

    async updateMapToIntramirror(id, channelId, imGenderCategoryId) {
        const imClient = new IntramirrorGenderMapApiClient(this._token, this._genderId, id);
        return imClient.save({
            id: id,
            channel_id: channelId,
            im_gender_category_id: imGenderCategoryId,
        })
    }

    async deleteMapToIntramirror(id) {
        const imClient = new IntramirrorGenderMapApiClient(this._token, this._genderId, id);
        imClient.remove()
    }

    async createMapToMiinto(channelId, mntName) {
        const mntClient = new MiintoGenderMapApiClient(this._token, this._genderId);
        return mntClient.save({
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async updateMapToMiinto(id, channelId, mntName) {
        const mntClient = new MiintoGenderMapApiClient(this._token, this._genderId, id);
        return mntClient.save({
            id: id,
            channel_id: channelId,
            mnt_name: mntName
        })
    }

    async deleteMapToMiinto(id) {
        const mntClient = new MiintoGenderMapApiClient(this._token, this._genderId, id);
        mntClient.remove()
    }
}