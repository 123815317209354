import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { WooCommerceModel } from '../models/woocommerce/WooCommerceModel';


const wooCommerceTagAdapter = createEntityAdapter({})

export const wooCommerceTagApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'woocommerce/tag/fetchAll', 
        async ({channelId, page, term}, thunkAPI) => {
            try {
                const token = wooCommerceTagApi._getToken(thunkAPI);
                const wchModel = new WooCommerceModel(token, channelId);

                /** @type {Pager} */
                const pager = await wchModel.getTags(page, term);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const wooCommerceTagSlice = createSlice({
    name: "woocommerce/tag",
    initialState: wooCommerceTagAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearTagList(state, action) {
            wooCommerceTagAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [wooCommerceTagApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'woocommerce/tag/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                wooCommerceTagAdapter.addMany(state, action.payload.entities)
            } else {
                wooCommerceTagAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [wooCommerceTagApi.fetchAll.rejected]: (state, action) => {
            state.status = 'woocommerce/tag/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllWooCommerceTag,
} = wooCommerceTagAdapter.getSelectors((state) => state.wooCommerceTagReducer)

export const { clearTagList } = wooCommerceTagSlice.actions;

export default wooCommerceTagSlice.reducer;
