import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class MiintoGenderMapApiClient extends AdvancedApiClient {

    static entityType = "MiintoGenderMap"

    constructor(token, genderId, id) {
        super();

        this.setEntityType(MiintoGenderMapApiClient.entityType)

        if (id !== undefined && id !== null) {
            this.setRoute("/api/v1/gender/{gid}/miinto-map/{id}")
                .setRouteParam("gid", genderId)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/gender/{gid}/miinto-map")
                .setRouteParam("gid", genderId);
        }

        if (token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}