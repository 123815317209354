import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class FarfetchSizeRangeApiClient extends AdvancedApiClient {

    static entityType = "FarfetchSizeRange"

    constructor(token, channelId, categoryId, brandId) {
        super();

        this.setEntityType(FarfetchSizeRangeApiClient.entityType)
            .setRoute("/api/v1/channel/{cid}/farfetch/category/{catid}/brand/{bid}/sizerange")
            .setRouteParam("cid", channelId)
            .setRouteParam("bid", brandId)
            .setRouteParam("catid", categoryId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}