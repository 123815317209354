import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { ChannelModel } from '../models/ChannelModel';


const channelAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const channelApi = {
    _model: null,
    _getModel: (thunkAPI) => {
        const token = thunkAPI.getState().identityReducer.token.signature;

        if(channelApi._model === null) {
            channelApi._model = new ChannelModel(token);
        } else {
            channelApi._model.setToken(token);
        }
        return channelApi._model;
    },
    fetchById: createAsyncThunk(
        'channel/fetchById', 
        async ({id}, thunkAPI) => {
            /** @type {ChannelModel} */
            const channelModel = channelApi._getModel(thunkAPI);

            try {
                return await channelModel.getChannelById(id)
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchAll: createAsyncThunk(
      'channel/fetchAll', 
      async ({page, term, enabled, synced, validTypes}, thunkAPI) => {
        /** @type {ChannelModel} */
        const channelModel = channelApi._getModel(thunkAPI);

        try {
            return await channelModel.getChannels(term, enabled, synced, validTypes);
        } catch (apiError) {
            return thunkAPI.rejectWithValue(apiError.toJson());
        }
      }
    ),
    create: createAsyncThunk(
        'channel/create', 
        async ({name, type, enabled, config, urlQueue}, thunkAPI) => {
            /** @type {ChannelModel} */
            const channelModel = channelApi._getModel(thunkAPI);

            try {
                return await channelModel.createChannel(name, type, enabled, config, urlQueue);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'channel/delete', 
        async ({channel}, thunkAPI) => {
            /** @type {ChannelModel} */
            const channelModel = channelApi._getModel(thunkAPI);

            try {
                await channelModel.deleteChannel(channel);
                return channel;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'channel/update', 
        async ({id, name, type, enabled, config, urlQueue}, thunkAPI) => {
            /** @type {ChannelModel} */
            const channelModel = channelApi._getModel(thunkAPI);

            try {
                return await channelModel.updateChannel(id, name, type, enabled, config, urlQueue)
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ), 
}

const channelSlice = createSlice({
    name: "channel",
    initialState: channelAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        showMoreChannels: true,
        lastAddedChannel: null,
        tryToSave: false,
    }),
    reducers: {
        setTerm(state, action) {
          state.term = action.payload
        },
        toggleChannelSave(state) {
            state.tryToSave = !state.tryToSave
        },
        resetStatus(state) {
            state.status = 'idle'
        },
        clearChannelList(state, action) {
            channelAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [channelApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'channel/fetchAll/succeeded'
            channelAdapter.setAll(state, action.payload)
            state.showMoreChannels = false;
            state.error = null;
        },
        [channelApi.fetchAll.rejected]: (state, action) => {
            state.status = 'channel/fetchAll/rejected'
            state.error = action.payload
        },
        [channelApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'channel/fetchById/succeeded'
            channelAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [channelApi.fetchById.rejected]: (state, action) => {
            state.status = 'channel/fetchById/rejected'
            state.error = action.payload
        },
        [channelApi.create.fulfilled]: (state, action) => {
            state.status = 'channel/create/succeeded'
            state.error = null;
            channelAdapter.addOne(state, action.payload);
            state.lastAddedChannel = action.payload;
        },
        [channelApi.create.rejected]: (state, action) => {
            state.status = 'channel/create/rejected';
            state.error = action.payload;
        },
        [channelApi.delete.fulfilled]: (state, action) => {
            state.status = 'channel/delete/succeeded'
            state.error = null;
            channelAdapter.removeOne(state, action.payload.id)
        },
        [channelApi.delete.rejected]: (state, action) => {
            state.status = 'channel/delete/rejected';
            state.error = action.payload;
        },
        [channelApi.update.fulfilled]: (state, action) => {
            state.status = 'channel/update/succeeded'
            state.error = null;
            channelAdapter.upsertOne(state, action.payload);
        },
        [channelApi.update.pending]: (state, action) => {
            state.status = 'channel/update/pending'
        },
        [channelApi.update.rejected]: (state, action) => {
            state.status = 'channel/update/rejected';
            state.error = action.payload;
        }
    }
});

export const {
    selectAll: selectAllChannels,
    selectById: selectChannelById,
} = channelAdapter.getSelectors((state) => state.channelReducer)

export const { setTerm, toggleChannelSave, resetStatus, clearChannelList } = channelSlice.actions;

export default channelSlice.reducer;
