import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class IntramirrorCategoryApiClient extends AdvancedApiClient {

    static entityType = "IntramirrorCategory"

    constructor(token, channelId) {
        super();

        this.setEntityType(IntramirrorCategoryApiClient.entityType)
            .setRoute("/api/v1/channel/{cid}/intramirror/category")
            .setRouteParam("cid", channelId)
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}