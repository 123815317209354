import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { ChannelSyncModel } from '../models/ChannelSyncModel';


const channelSyncAdapter = createEntityAdapter()

export const channelSyncApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'channelSync/fetchAll', 
        async ({channelId}, thunkAPI) => {
            try {
                const token = channelSyncApi._getToken(thunkAPI)
                const channelSyncModel = new ChannelSyncModel(token)

                return channelSyncModel.getChannelSyncStatusById(channelId)
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'channelSync/create', 
        async ({channelId, channelProductIds, channelProductStatusList, channelProductVariantIds, channelProductVariantStatusList}, thunkAPI) => {
            try {
                const token = channelSyncApi._getToken(thunkAPI)
                const channelSyncModel = new ChannelSyncModel(token)

                return channelSyncModel.enqueueChannelSyncRequest(channelId, channelProductIds, channelProductStatusList, channelProductVariantIds, channelProductVariantStatusList)
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const channelSyncSlice = createSlice({
    name: "channelSync",
    initialState: channelSyncAdapter.getInitialState({
        status: 'idle',
        error: null,
        syncStatus: null,
    }),
    reducers: {},
    extraReducers: {
        [channelSyncApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'channelSync/fetchAll/succeeded'
            state.error = null
            state.syncStatus = action.payload[0]
        },
        [channelSyncApi.fetchAll.rejected]: (state, action) => {
            state.status = 'channelSync/fetchAll/rejected'
            state.error = action.payload
        },
        [channelSyncApi.create.fulfilled]: (state, action) => {
            state.status = 'channelSync/create/succeeded'
            state.error = null
            state.syncStatus = action.payload
        },
        [channelSyncApi.create.pending]: (state, action) => {
            state.status = 'channelSync/create/pending'
        },
        [channelSyncApi.create.rejected]: (state, action) => {
            state.status = 'channelSync/create/rejected'
            state.error = action.payload
        },
        
    }
});


export const {
} = channelSyncAdapter.getSelectors((state) => state.brandReducer)

// export const { } = channelSyncSlice.actions;

export default channelSyncSlice.reducer;
