import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import identityReducer from "./modules/identity/reducers/identitySlice";
import userReducer from "./modules/user/reducers/userSlice";
import channelReducer from "./modules/channel-manager/reducers/channelSlice";
import productReducer from "./modules/channel-manager/reducers/productSlice";
import channelLogReducer from "./modules/channel-manager/reducers/channelLogSlice";
import channelSyncReducer from "./modules/channel-manager/reducers/channelSyncSlice";
import channelProductReducer from "./modules/channel-manager/reducers/channelProductSlice";
import channelProductVariantReducer from "./modules/channel-manager/reducers/channelProductVariantSlice";
import productMediaReducer from "./modules/channel-manager/reducers/productMediaSlice";
import productVariantReducer from "./modules/channel-manager/reducers/productVariantSlice";
import productVariantMediaReducer from "./modules/channel-manager/reducers/productVariantMediaSlice";
import brandReducer from "./modules/channel-manager/reducers/brandSlice";
import genderReducer from "./modules/channel-manager/reducers/genderSlice";
import categoryReducer from "./modules/channel-manager/reducers/categorySlice";
import colorReducer from "./modules/channel-manager/reducers/colorSlice";
import sizeReducer from "./modules/channel-manager/reducers/sizeSlice";
import seasonReducer from "./modules/channel-manager/reducers/seasonSlice";
import farfetchBoxReducer from "./modules/channel-manager/reducers/farfetchBoxSlice";
import farfetchBrandReducer from "./modules/channel-manager/reducers/farfetchBrandSlice";
import farfetchGenderReducer from "./modules/channel-manager/reducers/farfetchGenderSlice";
import farfetchCategoryReducer from "./modules/channel-manager/reducers/farfetchCategorySlice";
import farfetchCollectionReducer from "./modules/channel-manager/reducers/farfetchCollectionSlice";
import farfetchSizeRangeReducer from "./modules/channel-manager/reducers/farfetchSizeRangeSlice";
import farfetchSizeReducer from "./modules/channel-manager/reducers/farfetchSizeSlice";
import farfetchSeasonReducer from "./modules/channel-manager/reducers/farfetchSeasonSlice";
import intramirrorCategoryReducer from "./modules/channel-manager/reducers/intramirrorCategorySlice";
import miintoCategoryReducer from "./modules/channel-manager/reducers/miintoCategorySlice";
import wooCommerceCategoryReducer from "./modules/channel-manager/reducers/woocommerceCategorySlice";
import wooCommerceTagReducer from "./modules/channel-manager/reducers/woocommerceTagSlice";
import wooCommerceAttributeReducer from "./modules/channel-manager/reducers/woocommerceAttributeSlice";
import wooCommerceTermReducer from "./modules/channel-manager/reducers/woocommerceTermSlice";
import settingReducer from "./modules/settings/reducers/settingsSlice";
import notificationReducer from "./modules/push-notification/reducers/notificationSlice";
import avgAnalyticsReducer from './modules/channel-manager/reducers/avgAnalyticsSlice';
import avgAnalyticsBrandReducer from './modules/channel-manager/reducers/avgAnalyticsBrandSlice';
import avgAnalyticsCategoryReducer from './modules/channel-manager/reducers/avgAnalyticsCategorySlice';
import avgAnalyticsSeasonReducer from './modules/channel-manager/reducers/avgAnalyticsSeasonSlice';

const Store = configureStore({
  reducer: combineReducers({
    identityReducer,
    userReducer,
    channelReducer,
    productReducer,
    channelLogReducer,
    channelSyncReducer,
    channelProductReducer,
    channelProductVariantReducer,
    productMediaReducer,
    productVariantReducer,
    productVariantMediaReducer,
    brandReducer,
    categoryReducer,
    genderReducer,
    colorReducer,
    sizeReducer,
    seasonReducer,
    farfetchBoxReducer,
    farfetchBrandReducer, 
    farfetchGenderReducer, 
    farfetchCategoryReducer, 
    farfetchCollectionReducer, 
    farfetchSizeRangeReducer, 
    farfetchSizeReducer, 
    farfetchSeasonReducer, 
    intramirrorCategoryReducer, 
    miintoCategoryReducer, 
    wooCommerceCategoryReducer, 
    wooCommerceTagReducer, 
    wooCommerceAttributeReducer, 
    wooCommerceTermReducer, 
    settingReducer,
    notificationReducer,
    avgAnalyticsReducer,
    avgAnalyticsBrandReducer,
    avgAnalyticsCategoryReducer,
    avgAnalyticsSeasonReducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default Store;