import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { WooCommerceModel } from '../models/woocommerce/WooCommerceModel';


const wooCommerceAttributeAdapter = createEntityAdapter({})

export const wooCommerceAttributeApi = {
    _model: null,
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'woocommerce/attribute/fetchAll', 
        async ({channelId, page, term}, thunkAPI) => {
            try {
                const token = wooCommerceAttributeApi._getToken(thunkAPI);
                const wchModel = new WooCommerceModel(token, channelId);

                /** @type {Pager} */
                const pager = await wchModel.getAttributes(page, term);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const wooCommerceAttributeSlice = createSlice({
    name: "woocommerce/attribute",
    initialState: wooCommerceAttributeAdapter.getInitialState({
        status: 'idle',
        error: null,
        showMore: true,
    }),
    reducers: {
        clearAttributeList(state, action) {
            wooCommerceAttributeAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [wooCommerceAttributeApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'woocommerce/attribute/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                wooCommerceAttributeAdapter.addMany(state, action.payload.entities)
            } else {
                wooCommerceAttributeAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [wooCommerceAttributeApi.fetchAll.rejected]: (state, action) => {
            state.status = 'woocommerce/attribute/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllWooCommerceAttribute,
} = wooCommerceAttributeAdapter.getSelectors((state) => state.wooCommerceAttributeReducer)

export const { clearAttributeList } = wooCommerceAttributeSlice.actions;

export default wooCommerceAttributeSlice.reducer;
