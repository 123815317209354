import { JWTAuth } from "../../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../../lib/js-apiclient/src/AdvancedApiClient";

export class AttributeApiClient extends AdvancedApiClient {

    static entityType = "WooCommerceAttribute"

    constructor(token, channelId) {
        super();

        this.setEntityType(AttributeApiClient.entityType)
            .setRoute("/api/v1/channel/{id}/woocommerce/attribute")
            .setRouteParam("id", channelId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}